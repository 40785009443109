import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../App.css";

// styling the menu selection box
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
			width: 300,
		},
	},
};
// Dropdown for PICK BIAS names to filter out in team average lines table
const names = [
	"NO BIAS: AVE GAME",
	"HOME BIAS: OFFENSE",
	"HOME BIAS: DEFENSE",
	"HOME DOMINATES",
	"AWAY BIAS: OFFENSE",
	"AWAY BIAS: DEFENSE",
	"AWAY DOMINATES",
	"BOTH TEAMS: LOW SCORING",
	"BOTH TEAMS: HIGH SCORING",
];

// Handling the pick bias name dropdown while selecting
export default function PickBiasDropdown({ stateName, id, onSelectionChange , defaultValue}) {

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		onSelectionChange(value)
	};

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1, width: 200, outline: "none", border: "none" }}
				color="warning"
			>
				<InputLabel id={id} color="warning" sx={{ color: "#fff" }}>
					{stateName}
				</InputLabel>
				{/********DROPDOWN MENU***********/}
				<Select
					labelId={id}
					id="demo-multiple-name"
					value={defaultValue}
					onChange={handleChange}
					MenuProps={MenuProps}
					sx={{
						width: 200,
						borderBottom: "1px solid #ffce19",
						color: "#fff",
						textAlign: "left",
						fontSize: "12px",
						"& .MuiSvgIcon-root": {
							color: "#ffce19",
						},
					}}
				>
					{/*********OPTION**********/}
					{names.map((name) => (
						<MenuItem key={name} value={name}>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
