import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import updateTableData from "./data";
import "../App.css";
import Box from "@mui/material/Box"

// Custom Table for previous match score table

// Custom Styling for Table Cell and Table Head
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: "#17a4b8",
		color: theme.palette.common.white,
		fontSize: 12,
		margin: 0,
		padding: 3,
	},
	[`&.${tableCellClasses.body}`]: {
		color: "#ccc",
		fontSize: 12,
		minWidth: 55, // Adjust the width as needed
		textAlign: "left", // Center align all cells by default
		margin: 10,
		padding: 10,
	},
	"&.scoring-info, &.shooting, &.free-throws,&.team-rankings,&.rebounding,&.possession-stats,&.other-variables,&.opponent-scoring-info, &.opponent-shooting, &.opponent-free-throws,&.opponent-team-ranking,&.opponent-rebounding,&.opponent-possession-stats":
		{
			color: theme.palette.common.black,
			textAlign: "center",
		},
}));

// Styling the "Category" row by adding background color based  on class Name
const StyledTableRow = styled(TableRow)(({ theme, category }) => ({
	"&.scoring-info, &.shooting, &.free-throws,&.team-rankings,&.rebounding,&.possession-stats,&.other-variables":
		{
			backgroundColor: "#ffe499",
		},
	"&.opponent-scoring-info, &.opponent-shooting, &.opponent-free-throws,&.opponent-team-ranking,&.opponent-rebounding,&.opponent-possession-stats":
		{
			backgroundColor: "#fea284",
		},
}));


const formatValue = (value) => {
	if (typeof value === "number") {
		// Check if the value has decimal digits
		return value % 1 === 0 ? value : value.toFixed(2);
	}
	return value !== null && value !== undefined ? value : "-"; // whether value has a something other than null else '-'
};

export default function MatchTable({
	data,
	matchOne,
	matchTwo,
	away_category,
	
}) {
	const tableData = updateTableData(data, away_category);

	const Averages= data?.result_home?.Averages?.Averages;

	return (
		<Box sx={{paddingTop:"50px"}}>
			<Typography sx={{ fontSize: "22px", mb: 3, color: "#ffce19" }}>
				<center>PREVIOUS MATCHUP SCORE</center>
			</Typography>
			<br />
			<Paper
				sx={{
					width: 750,
					overflow: "hidden",
					background: "transparent",
					color: "#cccccc",
					border: "1px solid #cccccc",
				}}
			>
				<TableContainer sx={{ maxHeight: 510 }}>
					<Table
						sx={{ minWidth: 700 }}
						aria-label="customized table"
						stickyHeader
					>
						<TableHead>
							{tableData !== null && (
								<TableRow>
									{Object.entries(tableData[0]).map(([key, value], index) => (
										<StyledTableCell key={index}>
											<center>{value}</center>
										</StyledTableCell>
									))}
								</TableRow>
							)}
						</TableHead>
						<TableBody>
							{tableData !== null &&
								tableData.slice(1).map((row, rowIndex) => {
									const category = row.category
										.toLowerCase()
										.replace(/\s/g, "-"); // Convert category to class name format
									return (
										<StyledTableRow key={rowIndex} className={category}>
											{Object.entries(row).map(([key, value], colIndex) => (
												<StyledTableCell
													className={category}
													key={colIndex}
													align="center"
													sx={{ width: colIndex === 1 && 240, fontWeight: value==="TOTAL" ? "bolder" : "", }}
												>
													{formatValue(value)}
												</StyledTableCell>
											))}
										</StyledTableRow>
									);
								})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</Box>
	);
}
