import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Dropdown from "./Dropdown";
import logo from "../images/logo.png";
import { Blocks } from "react-loader-spinner";
import { AssistTable, BlocksTable, PPGTable, PTPropsTable, ReboundsTable, StealsTable } from './TableOne';
import { Link, useLocation } from 'react-router-dom';
import PlayersDropdown from './PlayersDropdown'

function Props() {
    const [propsData, setPropsData] = React.useState()
    const [loading, setLoading] = React.useState(true);
    const location = useLocation();
  const { homePlayersData, match2} = location.state || {};
    const [opponentTeam, setOpponentTeam]=React.useState(match2);
    const [playerName, setPlayerName]=React.useState(homePlayersData?.players_data[4]?.player)
    const handleOpponentTeamChange = (value) => {
        setOpponentTeam(value);
        setLoading(true);
      };
    React.useEffect(() => {
        setLoading(true);
        fetch(`https://degeneratesodds.sataware.dev:5000/api/get_pg_props?pg_props_player_names=${playerName}&pg_props_opponent=${opponentTeam}`)
          .then((response) => response.json())
          .then((data) => {
            setPropsData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      }, [opponentTeam, playerName]);

      const handleSelectionChange = (selectedPlayer) => {
        setPlayerName(selectedPlayer)
      };
  return (
    <div style={{color:"white"}}>
             {/******TITLE*****/}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          align="center"
          variant="h5"
          sx={{ mt: 2, color: "#ffce19" }}
        > <Link to = "/">
          <img src={logo} width="200px" alt="logo" />
          </Link>
        </Typography>
      </Box>
      <br />
      <Box
        sx={{ display: "flex", justifyContent: "center", gap:"60px" }}
      >
      <PlayersDropdown
        stateName="Select Player Name"
        id="home-player-select"
        options={homePlayersData?.players_data} // Pass homePlayersData as options
        onSelectionChange={handleSelectionChange}
        defaultValue={playerName}
      />

<Dropdown
          stateName="Opponent Team"
          id="opponent-dropdown"
          onSelectionChange={handleOpponentTeamChange}
          defaultValue={opponentTeam}
        />
</Box>
 <br />
      <br />
      {loading  ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              marginTop: "10px",
              color: "#ffce19",
              fontWeight: "bold",
            }}
          >
            LOADING DATA
          </Typography>
          <Blocks
            height="80"
            width="80"
            color="#ffce19"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            visible={true}
          />
        </Box>
      ) : (
    <div className='' style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
    <div className='' style={{display:"flex", justifyContent:"center", gap:"30px"}}>
      <PTPropsTable tableWidth={600} propsData={propsData}/>
      <PPGTable tableWidth={600} propsData={propsData} />
      </div>
      <br />
      <br />
      <br/>
      <div className='' style={{display:"flex", justifyContent:"center", gap:"30px"}}>
      <AssistTable tableWidth={600} propsData={propsData} />
      <ReboundsTable tableWidth={600} propsData={propsData}/>
      </div>
      <br />
      <br />
      <br/>
      <div className='' style={{display:"flex", justifyContent:"center", gap:"30px"}}>
      <StealsTable tableWidth={600} propsData={propsData} />
      <BlocksTable tableWidth={600} propsData={propsData} />
      </div>
      <br />
      <br />
      </div>
      )}
    </div>
  )
}

export default Props