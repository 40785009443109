import React from "react";
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Typography } from "@mui/material";

const GameContext = ({ tableWidth, data}) => {
     const game_context = data?.full_upper_tables?.["GAME CONTEXT"];

    return (
        <TableContainer
            component={Paper}
            style={{
                margin: "0 20px",
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid #cccccc",
                width: tableWidth,
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{ textTransform: "uppercase", marginTop: "10px" }}
            >
                <center>Game Context</center>
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>INJURY ADV</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>REST DAYS</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>HOME/TRAVEL STRETCH</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>MOMENTUM</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>MOTIVATION</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>PACE OF PLAY</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 100 }}>
                            <center>{game_context?.INJURY_ADV_ROW1}</center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>
                               {game_context?.REST_TRAVEL_IMPACT_ROW1}
                            </center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 60 }}>
                            <center>
                               {game_context?.HOME_TRAVEL_STRETCH_ROW1}
                            </center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>
                               {game_context?.MOMENTUM_ROW1}
                            </center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>{game_context?.MOTIVATION_ROW1}</center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>{game_context?.PACE_OF_PLAY_ROW1}</center>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 100 }}>
                            <center>{game_context?.INJURY_ADV_ROW2}</center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 60 }}>
                            <center>
                            {game_context?.REST_TRAVEL_IMPACT_ROW2}
                            </center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>
                            {game_context?.HOME_TRAVEL_STRETCH_ROW2}
                            </center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>
                            {game_context?.MOMENTUM_ROW2}
                            </center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>{game_context?.MOTIVATION_ROW2}</center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>{game_context?.PACE_OF_PLAY_ROW2}</center>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GameContext;
