import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";

const HorizontalCustomTable = ({
  title,
  headers,
  data,
  tableWidth,
  border,
  borderCell,
  paddingCell,
  fontSize,
  highlightText,
}) => {
  const formatValue = (value) => {
    if (typeof value === "number") {
      // Check if the value has decimal digits
      return value % 1 === 0 ? value : value.toFixed(2);
    }
    return value !== null && value !== undefined ? value : "-"; // whether value has something other than null else '-'
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        margin: "0 20px",
        backgroundColor: "transparent",
        color: "white",
        width: tableWidth,
        border: border,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ textTransform: "uppercase", marginTop: "10px" }}
        mb={1}
      >
        <center>{title}</center>
      </Typography>
      <Table>
        <TableHead>
          {/***************TABLE HEADER*****************/}
          <TableRow>
            {headers?.map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  textAlign: "center",
                  color: "#ffce19",
                  textTransform: "uppercase",
                  fontSize: fontSize ?? "10px",
                  border: borderCell,
                }}
                
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/***************TABLE DATA*****************/}
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => {
                if (cellIndex === 0 && rowIndex === 3) {
                  // Render "rest/travel impact" cell with rowspan
                  return (
                    <TableCell
                      key={cellIndex}
                      rowSpan={3}
                      sx={{
                        textAlign: "center",
                        color: highlightText && cellIndex === 0 ? "#ffce19" : "#cccccc",
                        fontSize: fontSize ?? "10px",
                        border: borderCell,
                        padding: paddingCell,
                      }}
                    >
                      {formatValue(cell)}
                    </TableCell>
                  );
                } else if (cellIndex === 0 && rowIndex > 3) {
                  // Skip the cell in the subsequent rows
                  return null;
                } else {
                  // Render regular cells
                  return (
                    <TableCell
                      key={cellIndex}
                      sx={{
                        textAlign: "center",
                        color: highlightText && cellIndex === 0 ? "#ffce19" : "#cccccc",
                        fontSize: fontSize ?? "10px",
                        border: borderCell,
                        padding: paddingCell,
						            borderLeft: cellIndex===1 && "1px solid #ccc"
                      }}
                    >
                      {formatValue(cell)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HorizontalCustomTable;
