import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";

const PreviousMatchUpCustom = ({
  title,
  headers,
  data,
  tableWidth,
  border,
  borderCell,
  paddingCell,
  fontSize,
  highlightText,
}) => {
    // Function to format date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };
  const formatValue = (value) => {
    if (typeof value === "string") {
        if (value === "won") return "W";
        if (value === "lost") return "L";
        if(value ==="home") return "H";
        if(value==="away") return "A";
        if (/^\d{4}-\d{2}-\d{2}$/.test(value)) return formatDate(value);
      }
    if (typeof value === "number") {
      return value % 1 === 0 ? value : value.toFixed(2);
    }
    return value !== null && value !== undefined ? value : "-";
  };

  return (
    <TableContainer
      component={Paper}
      style={{
        margin: "0 20px",
        backgroundColor: "transparent",
        color: "white",
        width: tableWidth,
        border: border,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ textTransform: "uppercase", marginTop: "10px" }}
      >
        <center>{title}</center>

      </Typography>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            {headers?.map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  textAlign: "center",
                  color: "#ffce19",
                  textTransform: "uppercase",
                  fontSize: fontSize ?? "10px",
                  border: borderCell,
                }}
              >
                {formatValue(header)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => {
                if (
                  (cellIndex === 0 || cellIndex === 7 || cellIndex === 8 || cellIndex === 9 || cellIndex === 10 || cellIndex===11 || cellIndex===12 || cellIndex===13 || cellIndex===15 || cellIndex===16 || cellIndex===17) &&
                  (rowIndex===0 || rowIndex===5)
                ) {
                  return (
                    <TableCell
                      key={cellIndex}
                      rowSpan={4}
                      sx={{
                        textAlign: "center",
                        color:  cellIndex === 0 ? "#ffce19" : "#cccccc",
                        fontSize: fontSize ?? "10px",
                        border: borderCell,
                        padding: paddingCell,
                        borderRight:( cellIndex===0 || cellIndex===7 || cellIndex === 8 || cellIndex === 9 || cellIndex===11 || cellIndex===12 || cellIndex===13 || cellIndex===15 || cellIndex===16) && "1px solid #ccc",
                        borderLeft:(cellIndex===11 || cellIndex===14 || cellIndex===15 ) && "1px solid #ccc",
                      }}
                    >
                        
                      {formatValue(cell)}
                    </TableCell>
                  );
                } else if (
                  (cellIndex === 0 || cellIndex === 7 || cellIndex === 8 || cellIndex === 9 || cellIndex === 10 || cellIndex===11 || cellIndex===12 || cellIndex===13 || cellIndex===15 || cellIndex===16 || cellIndex===17) &&
                  (rowIndex ===1 || rowIndex===2 || rowIndex===3 || rowIndex===8 || rowIndex===6 || rowIndex===7 ) 
                ) {
                  return null;
                } else {
                  return ( 
                    <TableCell
                      key={cellIndex}
                      sx={{
                        
                        textAlign: "center",
                        color: cell==="won" || cell==="lost" ||cell==="home" 
                        || cell==="away" || cell==="HOME/AWAY" ? "#ffce19" : "#cccccc",
                        fontSize: fontSize ?? "10px",
                        border: borderCell,
                        padding: paddingCell,
                        borderRight:(cellIndex===6 && rowIndex!==4) && "1px solid #ccc",
                      }}
                    >
                    
                      {formatValue(cell)}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PreviousMatchUpCustom;
