import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";
const PreviousH2HCustom = ({
    title,
    headers,
    data,
    tableWidth,
    border,
    borderCell,
    paddingCell,
    fontSize,
    highlightText,
  }) => {
    const formatValue = (value) => {
      if (typeof value === "string") {
        if (value === "WIN") return "W";
        if (value === "LOSE") return "L";
        
      }
      if (typeof value === "number") {
        return value % 1 === 0 ? value : value.toFixed(2);
      }
      return value !== null && value !== undefined ? value : "-";
    };
  
    return (
      <TableContainer
        component={Paper}
        style={{
          margin: "0 20px",
          backgroundColor: "transparent",
          color: "white",
          width: tableWidth,
          border: border,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ textTransform: "uppercase", marginTop: "10px" }}
          mb={2}
        >
          <center>{title}</center>
          
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {headers?.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    textAlign: "center",
                    color: "#ffce19",
                    textTransform: "uppercase",
                    fontSize: fontSize ?? "10px",
                    border: borderCell,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((cell, cellIndex) => {
                  if (
                    (cellIndex === 0 || cellIndex === 6 || cellIndex === 7) &&
                    rowIndex % 2 === 0
                  ) {
                    return (
                      <TableCell
                        key={cellIndex}
                        rowSpan={2}
                        sx={{
                          textAlign: "center",
                          color: 
                            (cellIndex === 0) ? "#ffce19" : 
                            "#cccccc",
                          fontSize: fontSize ?? "10px",
                          border: borderCell,
                          padding: paddingCell,
                          borderRight:(cellIndex===6 || cellIndex===0 || cellIndex===7 ||  cellIndex===11) && "1px solid #ccc",
                          borderLeft:(cellIndex===6) && "1px solid #ccc",
                        }}
                      >
                        {formatValue(cell)}
                      </TableCell>
                    );
                  } else if (
                    (cellIndex === 0 || cellIndex === 6 || cellIndex === 7) &&
                    rowIndex % 2 === 1
                  ) {
                    return null;
                  } else {
                    return (
                      <TableCell
                        key={cellIndex}
                        sx={{
                          textAlign: "center",
                          color: 
                            (cell==="WIN" || cell==="LOSE") ? "#ffce19" : 
                            "#cccccc",
                          fontSize: fontSize ?? "10px",
                          border: borderCell,
                          padding: paddingCell,
                        }}
                      >
                        {formatValue(cell)}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };
  
  export default PreviousH2HCustom;