import * as React from "react";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../App.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const names = [
	"Atlanta",
	"Boston",
	"Brooklyn",
	"Charlotte",
	"Chicago",
	"Cleveland",
	"Dallas",
	"Denver",
	"Detroit",
	"Golden State",
	"Houston",
	"Indiana",
	"LA Clippers",
	"LA Lakers",
	"Memphis",
	"Miami",
	"Milwaukee",
	"Minnesota",
	"New Orleans",
	"New York",
	"Okla City",
	"Orlando",
	"Philadelphia",
	"Phoenix",
	"Portland",
	"Sacramento",
	"San Antonio",
	"Toronto",
	"Utah",
];

function getStyles(name, selectedName, theme) {
	return {
		fontWeight:
			selectedName === name
				? theme.typography.fontWeightMedium
				: theme.typography.fontWeightRegular,
		borderColor: "#ffce19",
	};
}

export default function SingleSelect({ stateName, id, onSelectionChange, defaultValue }) {
	const theme = useTheme();
	const [selectedName, setSelectedName] = React.useState("");

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedName(value);
		onSelectionChange(value);
	};

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1, width: 200, outline: "none", border: "none" }}
				color="warning"
			>
				<InputLabel id={id} color="warning" sx={{ color: "#fff" }}>
					{stateName}
				</InputLabel>
				<Select
					labelId={id}
					id="demo-single-name"
					value={defaultValue}
					onChange={handleChange}
					MenuProps={MenuProps}
					sx={{
						borderBottom: "1px solid #ffce19",
						color: "#fff",
						"& .MuiSvgIcon-root": {
							color: "#ffce19",
						},
					}}
				>
					{names.map((name) => (
						<MenuItem
							key={name}
							value={name}
							style={getStyles(name, selectedName, theme)}
						>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
