import React from "react";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Typography,
} from "@mui/material";
// Custom Table for MATCH OUTPUT TABLE
const OutputTable = ({
	title,
	headers,
	data,
	tableWidth,
	border,
	paddingCell,
}) => {
	const formatValue = (value) => {
		if (typeof value === "number") {
			// Check if the value has decimal digits
			return value % 1 === 0 ? value : value.toFixed(2);
		}
		return value !== null && value !== undefined ? value : "-"; // whether value has a something other than null else '-'
	};
	return (
		<TableContainer
			component={Paper}
			style={{
				margin: "0 20px",
				backgroundColor: "transparent",
				color: "white",
				width: tableWidth,
				border: border,
			}}
		>
			<Typography
				variant="subtitle1"
				sx={{ textTransform: "uppercase", marginTop: "10px" }}
			>
				<center>{title}</center>
			</Typography>
			<Table>
				{/*************TABLE HEADER****************/}
				<TableHead>
					<TableRow>
						{headers.map((header, index) => (
							<TableCell
								key={index}
								sx={{
									textAlign: "center",
									color: "#ffce19",
									fontSize: "12px",
									textTransform: "uppercase",
									borderBottom: "none",
								}}
							>
								{header}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				{/*************TABLE DATA (with conditional styling)****************/}
				<TableBody>
					{data.map((row, rowIndex) => (
						<TableRow key={rowIndex}>
							{row.map((cell, colIndex) => (
								<TableCell
									key={colIndex}
									sx={{
										textAlign: "center",
										color:
											rowIndex >= 9 && rowIndex <= 17 && colIndex === 2
												? "#cc3e00"
												: rowIndex >= 23 &&
												  rowIndex <= 24 &&
												  (colIndex < 2 || colIndex === 3 || colIndex === 4)
												? "#ff0000"
												: rowIndex >= 18 &&
												  rowIndex <= 21 &&
												  (colIndex === 1 || colIndex === 3)
												? "#ffce19"
												: "#cccccc",
										fontSize: "12px",
										border:
											rowIndex >= 18 &&
											rowIndex <= 21 &&
											(colIndex === 1 || colIndex === 3)
												? "1px solid #ccc"
												: "none",
										padding: paddingCell,
										paddingBottom:
											rowIndex === 17 || rowIndex === 8 ? "20px" : "",
										paddingTop:
											rowIndex === 22 || rowIndex === 23 || rowIndex === 25
												? "20px"
												: "",
										borderTop:
											rowIndex === 23 || rowIndex === 25
												? "1px solid #ccc"
												: "",
									
									}}
								>
									{formatValue(cell)}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default OutputTable;
