import React, { useState, useEffect } from "react";
import { Card, CardContent, Button, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import GameDetails from "./GameDetails";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";

const GameCarousel = ({ games, onDateChange, selectedDate }) => {
  const [filteredDates, setFilteredDates] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const uniqueDates = [...new Set(games.map((game) => game['UTC time']))];
    setFilteredDates(uniqueDates.slice(0, 7));
  }, [games]);

  const handlePrevDates = () => {
    const uniqueDates = [...new Set(games.map((game) => game['UTC time']))];
    const newIndex = Math.max(startIndex - 7, 0);
    setFilteredDates(uniqueDates.slice(newIndex, newIndex + 7));
    setStartIndex(newIndex);
  };

  const handleNextDates = () => {
    const uniqueDates = [...new Set(games.map((game) => game['UTC time']))];
    const newIndex = startIndex + 7;
    setFilteredDates(uniqueDates.slice(newIndex, newIndex + 7));
    setStartIndex(newIndex);
  };

  const handleDateClick = (date) => {
    onDateChange(date);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}`;
  };

  const gamesOnSelectedDate = games.filter((game) => game['UTC time'] === selectedDate);

  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          warning: { main: "#80670d" },
        },
      },
    },
  });

  return (
    <CssVarsProvider theme={theme}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: 900,
          backgroundColor: "transparent",
          color: "#ffce19",
        }}
      >
        <CardContent>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 16,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="text"
              color="warning"
              sx={{ color: "#ffce19" }}
              onClick={handlePrevDates}
              disabled={startIndex === 0}
            >
              <ChevronLeftIcon />
            </Button>
            {filteredDates.map((date) => (
              <Button
                key={date}
                variant={selectedDate === date ? "contained" : "outlined"}
                color="warning"
                sx={{ color: "#ffce19" }}
                onClick={() => handleDateClick(date)}
                style={{ margin: "0 4px" }}
              >
                {formatDate(date)}
              </Button>
            ))}
            <Button
              variant="text"
              color="warning"
              onClick={handleNextDates}
              sx={{ color: "#ffce19" }}
              disabled={startIndex + 7 >= games.length}
            >
              <ChevronRightIcon />
            </Button>
          </div>
          {gamesOnSelectedDate.length > 0 && (
            <div style={{ marginTop: 16 , marginLeft:50 }}>
              <GameDetails games={gamesOnSelectedDate} />
            </div>
          )}
        </CardContent>
      </Card>
    </CssVarsProvider>
  );
};

export default GameCarousel;
