import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const GameDetails = ({ games }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : games.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < games.length - 1 ? prevIndex + 1 : 0
    );
  };

  const game = games[currentIndex];

  const formatTimeTo12Hour = (date) => {
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
    };

  const utcTime = new Date(game?.["UTC time"] + " UTC");
  const userLocalTime = utcTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true ,timeZoneName: 'short' });
  const formattedTimeutc = formatTimeTo12Hour(utcTime);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  return (
    <Card sx={{ background: "transparent", border: "none", color: "#ffce19" }}>
      <Box display="flex" alignItems="center" justifyContent="space-evenly">
        {games.length > 1 && (
          <Button
            onClick={handlePrev}
            color="warning"
            variant="contained"
            style={{ marginRight: 8 }}
            disabled={currentIndex === 0}
            sx={{ borderRadius: "100px", padding: "6px", minWidth: "40px" }}
          >
            <ChevronLeftIcon />
          </Button>
        )}
        <CardContent>
          <Box display="flex" alignItems="center" mb={4}>
            <img
              src={`https://degeneratesodds.sataware.dev:5000${game?.away_logo}`}
              alt="away logo"
              width="35px"
            />
            &nbsp;&nbsp;&nbsp;<Typography variant="h5">{game.away}</Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <img
              src={`https://degeneratesodds.sataware.dev:5000${game?.home_logo}`}
              alt="home logo"
              width="35px"
            />
            &nbsp;&nbsp;&nbsp;<Typography variant="h5">{game?.home}</Typography>
          </Box>
        </CardContent>
        <CardContent>
          <Box display="flex" alignItems="center" mb={1}>
            <CalendarMonthIcon fontSize="small" />
            &nbsp;&nbsp;
            <Typography color="white">
              {formatDate(game['UTC time'])}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <AccessTimeFilledIcon fontSize="small" />
            &nbsp;&nbsp;
            <Typography color="white">{userLocalTime} | {game?.["local time"]} | {formattedTimeutc} UTC</Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={1}>
            <LocationOnIcon fontSize="small" />
            &nbsp;&nbsp;<Typography color="white">{game?.location}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <ScoreboardIcon fontSize="small" />
            &nbsp;&nbsp;
            <Typography color="white">{game?.hotness_score}</Typography>
          </Box>
        </CardContent>
        {games.length > 1 && (
          <Button
            onClick={handleNext}
            color="warning"
            variant="contained"
            disabled={currentIndex + 1 >= games.length}
            sx={{ borderRadius: "100px", padding: "6px", minWidth: "40px" }}
          >
            <ChevronRightIcon />
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default GameDetails;
