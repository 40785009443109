const updateTableData = (data, away_category) => {
	return [
		{
			stat: "STAT WT",
			category: "CATEGORY",
			rank: "RANK",
			statDiff: "STAT",
			Diff: "DIFF",
			PA: "POINTS ADDED",
			volatility: "VOLATILITY",
			extra: "EXTRA",
		},
		/**********************SCORING INFO*************************/
		{
			stat: "-",
			category: "-",
			rank: "-",
			statDiff: "-",
			Diff: "-",
			PA: data?.Extra?.Row_7_data?.H11_points_added,
			volatility: data?.Extra?.Row_7_data?.I11_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "-",
			rank: "-",
			statDiff: "-",
			Diff: "-",
			PA: data?.H12,
			volatility: data?.I12,
			extra: "-",
		},
		{
			stat: "-",
			category: "-",
			rank: "-",
			statDiff: "-",
			Diff: "-",
			PA: data?.Extra?.Row_10_data?.H13_points_added,
			volatility: data?.Extra?.Row_10_data?.I13_volatility,
			extra: "-",
		},
		{
			stat: "",
			category: "SCORING INFO",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: away_category ? "AWAY PPG" : "HOME PPG",
			rank: data?.scoring_info?.["PPG"]?.ppg_rank,
			statDiff: data?.scoring_info?.["PPG"]?.ppg_stat,
			Diff: data?.scoring_info?.["PPG"]?.ppg_diff,
			PA: data?.scoring_info?.["PPG"]?.ppg_points_added,
			volatility: data?.scoring_info?.["PPG"]?.ppg_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "CLOSE GAME WIN %",
			rank: data?.scoring_info?.["CLOSE GAME WIN %"]
				?.close_game_win_percent_rank,
			statDiff:
				data?.scoring_info?.["CLOSE GAME WIN %"]?.close_game_win_percent_stat,
			Diff: data?.scoring_info?.["CLOSE GAME WIN %"]
				?.close_game_win_percent_diff,
			PA: data?.scoring_info?.["CLOSE GAME WIN %"]
				?.close_game_win_percent_points_added,
			volatility:
				data?.scoring_info?.["CLOSE GAME WIN %"]
					?.close_game_win_percent_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVERAGE PPG",
			rank: data?.scoring_info?.["AVERAGE PPG"]?.average_ppg_rank,
			statDiff: data?.scoring_info?.["AVERAGE PPG"]?.average_ppg_year_stat,
			Diff: data?.scoring_info?.["AVERAGE PPG"]?.average_ppg_diff,
			PA: data?.scoring_info?.["AVERAGE PPG"]?.average_ppg_points_added,
			volatility: data?.scoring_info?.["AVERAGE PPG"]?.average_ppg_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVE PPG LAST 3 GAMES",
			rank: data?.scoring_info?.["AVE PPG LAST 3 GAMES"]
				?.ave_ppg_last_3_games_rank,
			statDiff:
				data?.scoring_info?.["AVE PPG LAST 3 GAMES"]?.ave_ppg_last_3_games_stat,
			Diff: data?.scoring_info?.["AVE PPG LAST 3 GAMES"]
				?.ave_ppg_last_3_games_diff,
			PA: data?.scoring_info?.["AVE PPG LAST 3 GAMES"]
				?.ave_ppg_last_3_games_points_added,
			volatility:
				data?.scoring_info?.["AVE PPG LAST 3 GAMES"]
					?.ave_ppg_last_3_games_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVERAGE POINTS PER MINUTE",
			rank: data?.scoring_info?.["AVERAGE POINTS PER MINUTE"]
				?.average_points_per_minute_rank,
			statDiff:
				data?.scoring_info?.["AVERAGE POINTS PER MINUTE"]
					?.average_points_per_minute_stat,
			Diff: data?.scoring_info?.["AVERAGE POINTS PER MINUTE"]
				?.average_points_per_minute_diff,
			PA: data?.scoring_info?.["AVERAGE POINTS PER MINUTE"]
				?.average_points_per_minute_points_added,
			volatility:
				data?.scoring_info?.["AVERAGE POINTS PER MINUTE"]
					?.average_points_per_minute_volatility,
			extra: "-",
		},
		{
			stat: "6",
			category: "OFFENSIVE EFFICIENCY",
			rank: data?.scoring_info?.["OFFENSIVE EFFICIENCY"]
				?.offensive_efficiency_rank,
			statDiff:
				data?.scoring_info?.["OFFENSIVE EFFICIENCY"]?.offensive_efficiency_stat,
			Diff: data?.scoring_info?.["OFFENSIVE EFFICIENCY"]
				?.offensive_efficiency_diff,
			PA: data?.scoring_info?.["OFFENSIVE EFFICIENCY"]
				?.offensive_efficiency_points_added,
			volatility:
				data?.scoring_info?.["OFFENSIVE EFFICIENCY"]
					?.offensive_efficiency_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVE SCORING MARGIN",
			rank: data?.scoring_info?.["AVE SCORING MARGIN"]?.ave_scoring_margin_rank,
			statDiff:
				data?.scoring_info?.["AVE SCORING MARGIN"]?.ave_scoring_margin_stat,
			Diff: data?.scoring_info?.["AVE SCORING MARGIN"]?.ave_scoring_margin_diff,
			PA: data?.scoring_info?.["AVE SCORING MARGIN"]
				?.ave_scoring_margin_points_added,
			volatility:
				data?.scoring_info?.["AVE SCORING MARGIN"]
					?.ave_scoring_margin_volatility,
			extra: "-",
		},
		{
			stat: "6",
			category: "DEFENSIVE EFFICIENCY",
			rank: data?.scoring_info?.["DEFENSIVE EFFICIENCY"]
				?.defensive_efficiency_rank,
			statDiff:
				data?.scoring_info?.["DEFENSIVE EFFICIENCY"]?.defensive_efficiency_stat,
			Diff: data?.scoring_info?.["DEFENSIVE EFFICIENCY"]
				?.defensive_efficiency_diff,
			PA: data?.scoring_info?.["DEFENSIVE EFFICIENCY"]
				?.defensive_efficiency_points_added,
			volatility:
				data?.scoring_info?.["DEFENSIVE EFFICIENCY"]
					?.defensive_efficiency_volatility,
			extra:
				data?.scoring_info?.["DEFENSIVE EFFICIENCY"]
					?.defensive_efficiency_extras,
		},
		{
			stat: "1.5",
			category: "FLOOR %",
			rank: data?.scoring_info?.["FLOOR %"]?.floor_percent_rank,
			statDiff: data?.scoring_info?.["FLOOR %"]?.floor_percent_stat,
			Diff: data?.scoring_info?.["FLOOR %"]?.floor_percent_diff,
			PA: data?.scoring_info?.["FLOOR %"]?.floor_percent_points_added,
			volatility: data?.scoring_info?.["FLOOR %"]?.floor_percent_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: away_category ? "AWAY SCORING MARGIN" : "HOME SCORING MARGIN",
			rank: data?.scoring_info?.["SCORING MARGIN"]?.scoring_margin_rank,
			statDiff: data?.scoring_info?.["SCORING MARGIN"]?.scoring_margin_stat,
			Diff: data?.scoring_info?.["SCORING MARGIN"]?.scoring_margin_diff,
			PA: data?.scoring_info?.["SCORING MARGIN"]?.scoring_margin_points_added,
			volatility:
				data?.scoring_info?.["SCORING MARGIN"]?.scoring_margin_volatility,
			extra: "-",
		},
		/**********************TEAM RANKINGS*************************/
		{
			stat: "",
			category: "TEAM RANKINGS",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "6",
			category: "STRENGTH OF SCHEDULE",
			rank: data?.team_rankings?.["strength_of_schedule"]
				?.strength_of_schedule_rank,
			statDiff:
				data?.team_rankings?.["strength_of_schedule"]
					?.strength_of_schedule_stat,
			Diff: data?.team_rankings?.["strength_of_schedule"]
				?.strength_of_schedule_diff,
			PA: data?.team_rankings?.["strength_of_schedule"]
				?.strength_of_schedule_points_added,
			volatility:
				data?.team_rankings?.["strength_of_schedule"]
					?.strength_of_schedule_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "POWER RANK",
			rank: data?.team_rankings?.["power_rank"]?.power_rank_rank,
			statDiff: data?.team_rankings?.["power_rank"]?.power_rank_stat,
			Diff: data?.team_rankings?.["power_rank"]?.power_rank_diff,
			PA: data?.team_rankings?.["power_rank"]?.power_rank_points_added,
			volatility: data?.team_rankings?.["power_rank"]?.power_rank_volatility,
			extra: data?.team_rankings?.["power_rank"]?.power_rank_extras,
		},
		{
			stat: "6",
			category: away_category ? "AWAY RANK" : "HOME RANK",
			rank: data?.team_rankings?.["rank"]?.rank_rank,
			statDiff: data?.team_rankings?.["rank"]?.rank_stat,
			Diff: data?.team_rankings?.["rank"]?.rank_diff,
			PA: data?.team_rankings?.["rank"]?.rank_points_added,
			volatility: data?.team_rankings?.["rank"]?.rank_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "DIVISION RANK",
			rank: data?.team_rankings?.["division_rank"]?.division_rank_rank,
			statDiff: data?.team_rankings?.["division_rank"]?.division_rank_stat,
			Diff: data?.team_rankings?.["division_rank"]?.division_rank_diff,
			PA: data?.team_rankings?.["division_rank"]?.division_rank_points_added,
			volatility:
				data?.team_rankings?.["division_rank"]?.division_rank_volatility,
			extra: "-",
		},
		{
			stat: "2",
			category: "LAST 10 GAMES RANK",
			rank: data?.team_rankings?.["last_10_games_rank"]
				?.last_10_games_rank_rank,
			statDiff:
				data?.team_rankings?.["last_10_games_rank"]?.last_10_games_rank_stat,
			Diff: data?.team_rankings?.["last_10_games_rank"]
				?.last_10_games_rank_diff,
			PA: data?.team_rankings?.["last_10_games_rank"]
				?.last_10_games_rank_points_added,
			volatility:
				data?.team_rankings?.["last_10_games_rank"]
					?.last_10_games_rank_volatility,
			extra: "-",
		},

		/**********************SHOOTING*************************/
		{
			stat: "",
			category: "SHOOTING",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "6",
			category: "EFFECTIVE FIELD GOAL %",
			rank: data?.shooting?.["EFFECTIVE FIELD GOAL %"]
				?.effective_field_goal_percentage_rank,
			statDiff:
				data?.shooting?.["EFFECTIVE FIELD GOAL %"]
					?.effective_field_goal_percentage_stat,
			Diff: data?.shooting?.["EFFECTIVE FIELD GOAL %"]
				?.effective_field_goal_percentage_diff,
			PA: data?.shooting?.["EFFECTIVE FIELD GOAL %"]
				?.effective_field_goal_percentage_points_added,
			volatility:
				data?.shooting?.["EFFECTIVE FIELD GOAL %"]
					?.effective_field_goal_percentage_volatility,
			extra: "-",
		},
		{
			stat: "1.5",
			category: "THREE POINT %",
			rank: data?.shooting?.["THREE POINT %"]?.three_point_percentage_rank,
			statDiff: data?.shooting?.["THREE POINT %"]?.three_point_percentage_stat,
			Diff: data?.shooting?.["THREE POINT %"]?.three_point_percentage_diff,
			PA: data?.shooting?.["THREE POINT %"]
				?.three_point_percentage_points_added,
			volatility:
				data?.shooting?.["THREE POINT %"]?.three_point_percentage_volatility,
			extra: "-",
		},
		{
			stat: "1.5",
			category: "TWO POINT %",
			rank: data?.shooting?.["TWO POINT %"]?.two_point_percentage_rank,
			statDiff: data?.shooting?.["TWO POINT %"]?.two_point_percentage_stat,
			Diff: data?.shooting?.["TWO POINT %"]?.two_point_percentage_diff,
			PA: data?.shooting?.["TWO POINT %"]?.two_point_percentage_points_added,
			volatility:
				data?.shooting?.["TWO POINT %"]?.two_point_percentage_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "BLOCK %",
			rank: data?.shooting?.["BLOCK %"]?.block_percentage_rank,
			statDiff: data?.shooting?.["BLOCK %"]?.block_percentage_stat,
			Diff: data?.shooting?.["BLOCK %"]?.block_percentage_diff,
			PA: data?.shooting?.["BLOCK %"]?.block_percentage_points_added,
			volatility: data?.shooting?.["BLOCK %"]?.block_percentage_volatility,
			extra: "-",
		},
		{
			stat: "6",
			category: "TRUE SHOOTING %",
			rank: data?.shooting?.["TRUE SHOOTING %"]?.true_shooting_percentage_rank,
			statDiff:
				data?.shooting?.["TRUE SHOOTING %"]?.true_shooting_percentage_stat,
			Diff: data?.shooting?.["TRUE SHOOTING %"]?.true_shooting_percentage_diff,
			PA: data?.shooting?.["TRUE SHOOTING %"]
				?.true_shooting_percentage_points_added,
			volatility:
				data?.shooting?.["TRUE SHOOTING %"]
					?.true_shooting_percentage_volatility,
			extra: "-",
		},

		/**********************FREE THROWS*************************/
		{
			stat: "",
			category: "FREE THROWS",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "1.5",
			category: "FREE THROW ATT PER PLAY",
			rank: data?.free_throws?.["FREE THROW ATT PER PLAY"]
				?.free_throw_attempt_per_play_rank,
			statDiff:
				data?.free_throws?.["FREE THROW ATT PER PLAY"]
					?.free_throw_attempt_per_play_stat,
			Diff: data?.free_throws?.["FREE THROW ATT PER PLAY"]
				?.free_throw_attempt_per_play_diff,
			PA: data?.free_throws?.["FREE THROW ATT PER PLAY"]
				?.free_throw_attempt_per_play_points_added,
			volatility:
				data?.free_throws?.["FREE THROW ATT PER PLAY"]
					?.free_throw_attempt_per_play_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "FREE THROW %",
			rank: data?.free_throws?.["FREE THROW %"]?.free_throw_percentage_rank,
			statDiff: data?.free_throws?.["FREE THROW %"]?.free_throw_percentage_stat,
			Diff: data?.free_throws?.["FREE THROW %"]?.free_throw_percentage_diff,
			PA: data?.free_throws?.["FREE THROW %"]
				?.free_throw_percentage_point_added,
			volatility:
				data?.free_throws?.["FREE THROW %"]?.free_throw_percentage_volatility,
			extra: "-",
		},
		{
			stat: "6",
			category: "FREE THROW EFFICIENCY",
			rank: data?.free_throws?.["FREE THROW EFFICIENCY"]
				?.free_throw_efficiency_rank,
			statDiff:
				data?.free_throws?.["FREE THROW EFFICIENCY"]
					?.free_throw_efficiency_stat,
			Diff: data?.free_throws?.["FREE THROW EFFICIENCY"]
				?.free_throw_efficiency_diff,
			PA: data?.free_throws?.["FREE THROW EFFICIENCY"]
				?.free_throw_efficiency_points_added,
			volatility:
				data?.free_throws?.["FREE THROW EFFICIENCY"]
					?.free_throw_efficiency_volatility,
			extra: "-",
		},

		/**********************REBOUNDING*************************/
		{
			stat: "",
			category: "REBOUNDING",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "4",
			category: "OFFENSIVE REBOUNDING %",
			rank: data?.rebounding?.["OFFENSIVE REBOUNDING %"]
				?.offensive_rebounding_percentage_rank,
			statDiff:
				data?.rebounding?.["OFFENSIVE REBOUNDING %"]
					?.offensive_rebounding_percentage_stat,
			Diff: data?.rebounding?.["OFFENSIVE REBOUNDING %"]
				?.offensive_rebounding_percentage_diff,
			PA: data?.rebounding?.["OFFENSIVE REBOUNDING %"]
				?.offensive_rebounding_percentage_points_added,
			volatility:
				data?.rebounding?.["OFFENSIVE REBOUNDING %"]
					?.offensive_rebounding_percentage_volatility,
			extra: "-",
		},
		{
			stat: "4",
			category: "DEFENSIVE REBOUND %",
			rank: data?.rebounding?.["DEFENSIVE REBOUND %"]
				?.defensive_rebounding_percentage_rank,
			statDiff:
				data?.rebounding?.["DEFENSIVE REBOUND %"]
					?.defensive_rebounding_percentage_stat,
			Diff: data?.rebounding?.["DEFENSIVE REBOUND %"]
				?.defensive_rebounding_percentage_diff,
			PA: data?.rebounding?.["DEFENSIVE REBOUND %"]
				?.defensive_rebounding_percentage_points_added,
			volatility:
				data?.rebounding?.["DEFENSIVE REBOUND %"]
					?.defensive_rebounding_percentage_volatility,
			extra:
				data?.rebounding?.["DEFENSIVE REBOUND %"]
					?.defensive_rebounding_percentage_extras,
		},
		{
			stat: "4",
			category: "EXTRA SCORING CHANCES",
			rank: data?.rebounding?.["EXTRA SCORING CHANCES"]
				?.extra_scoring_chances_rank,
			statDiff:
				data?.rebounding?.["EXTRA SCORING CHANCES"]?.extra_scoring_chances_stat,
			Diff: data?.rebounding?.["EXTRA SCORING CHANCES"]
				?.extra_scoring_chances_diff,
			PA: data?.rebounding?.["EXTRA SCORING CHANCES"]
				?.extra_scoring_chances_points_added,
			volatility:
				data?.rebounding?.["EXTRA SCORING CHANCES"]
					?.extra_scoring_chances_volatility,
			extra: "-",
		},

		/**********************POSSESSION STATS*************************/
		{
			stat: "",
			category: "POSSESSION STATS",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "POSSESSIONS PER GAME",
			rank: data?.possession_stats?.["POSSESSIONS PER GAME"]
				?.possessions_per_game_rank,
			statDiff:
				data?.possession_stats?.["POSSESSIONS PER GAME"]
					?.possessions_per_game_stat,
			Diff: data?.possession_stats?.["POSSESSIONS PER GAME"]
				?.possessions_per_game_diff,
			PA: data?.possession_stats?.["POSSESSIONS PER GAME"]
				?.possessions_per_game_points_added,
			volatility:
				data?.possession_stats?.["POSSESSIONS PER GAME"]
					?.possessions_per_game_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVERAGE SHOOTING RANK",
			rank: data?.possession_stats?.["AVERAGE SHOOTING RANK"]
				?.average_shooting_rank_rank,
			statDiff:
				data?.possession_stats?.["AVERAGE SHOOTING RANK"]
					?.average_shooting_rank_stat,
			Diff: data?.possession_stats?.["AVERAGE SHOOTING RANK"]
				?.average_shooting_rank_diff,
			PA: data?.possession_stats?.["AVERAGE SHOOTING RANK"]
				?.average_shooting_points_added,
			volatility:
				data?.possession_stats?.["AVERAGE SHOOTING RANK"]
					?.average_shooting_rank_volatility,
			extra: "-",
		},
		{
			stat: "4",
			category: "EFFECTIVE POSSESSION RATIO",
			rank: data?.possession_stats?.["EFFECTIVE POSSESSION RATIO"]
				?.effective_possession_ratio_rank,
			statDiff:
				data?.possession_stats?.["EFFECTIVE POSSESSION RATIO"]
					?.effective_possession_ratio_stat,
			Diff: data?.possession_stats?.["EFFECTIVE POSSESSION RATIO"]
				?.effective_possession_ratio_diff,
			PA: data?.possession_stats?.["EFFECTIVE POSSESSION RATIO"]
				?.effective_possession_ratio_points_added,
			volatility:
				data?.possession_stats?.["EFFECTIVE POSSESSION RATIO"]
					?.effective_possession_ratio_volatility,
			extra: "-",
		},
		{
			stat: "4",
			category: "ASSIST TO TURNOVER RATIO",
			rank: data?.possession_stats?.["ASSIST TO TURNOVER RATIO"]
				?.assist_to_turnover_ratio_rank,
			statDiff:
				data?.possession_stats?.["ASSIST TO TURNOVER RATIO"]
					?.assist_to_turnover_ratio_stat,
			Diff: data?.possession_stats?.["ASSIST TO TURNOVER RATIO"]
				?.assist_to_turnover_ratio_diff,
			PA: data?.possession_stats?.["ASSIST TO TURNOVER RATIO"]
				?.assist_to_turnover_ratio_points_added,
			volatility:
				data?.possession_stats?.["ASSIST TO TURNOVER RATIO"]
					?.assist_to_turnover_ratio_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "TURNOVERS PER POSSESSION",
			rank: data?.possession_stats?.["TURNOVERS PER POSSESSION"]
				?.turnovers_per_possession_rank,
			statDiff:
				data?.possession_stats?.["TURNOVERS PER POSSESSION"]
					?.turnovers_per_possession_stat,
			Diff: data?.possession_stats?.["TURNOVERS PER POSSESSION"]
				?.turnovers_per_possession_diff,
			PA: data?.possession_stats?.["TURNOVERS PER POSSESSION"]
				?.turnovers_per_possession_points_added,
			volatility:
				data?.possession_stats?.["TURNOVERS PER POSSESSION"]
					?.turnovers_per_possession_volatility,
			extra:
				data?.possession_stats?.["TURNOVERS PER POSSESSION"]
					?.turnovers_per_possession_extras,
		},

		{
			stat: "1.5",
			category: "ASSIST TO FG RATIO",
			rank: data?.possession_stats?.["ASSIST TO FG RATIO"]
				?.assist_to_fg_ratio_rank,
			statDiff:
				data?.possession_stats?.["ASSIST TO FG RATIO"]?.assist_to_fg_ratio_stat,
			Diff: data?.possession_stats?.["ASSIST TO FG RATIO"]
				?.assist_to_fg_ratio_diff,
			PA: data?.possession_stats?.["ASSIST TO FG RATIO"]
				?.assist_to_fg_ratio_points_added,
			volatility:
				data?.possession_stats?.["ASSIST TO FG RATIO"]
					?.assist_to_fg_ratio_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "CLOSE GAME WIN %",
			rank: data?.possession_stats?.["POSSESSION CLOSE GAME WIN %"]
				?.possession_close_game_win_percent_rank,
			statDiff:
				data?.possession_stats?.["POSSESSION CLOSE GAME WIN %"]
					?.possession_close_game_win_percent_stat,
			Diff: data?.possession_stats?.["POSSESSION CLOSE GAME WIN %"]
				?.possession_close_game_win_percent_diff,
			PA: data?.possession_stats?.["POSSESSION CLOSE GAME WIN %"]
				?.possession_close_game_win_percent_points_added,
			volatility:
				data?.possession_stats?.["POSSESSION CLOSE GAME WIN %"]
					?.possession_close_game_win_percent_volatility,
			extra: "-",
		},

		{
			stat: "1.5",
			category: "FOULS PER POSSESSION",
			rank: data?.possession_stats?.["FOULS PER POSSESSION"]
				?.fouls_per_possession_rank,
			statDiff:
				data?.possession_stats?.["FOULS PER POSSESSION"]
					?.fouls_per_possession_stat,
			Diff: data?.possession_stats?.["FOULS PER POSSESSION"]
				?.fouls_per_possession_diff,
			PA: data?.possession_stats?.["FOULS PER POSSESSION"]
				?.fouls_per_possession_points_added,
			volatility:
				data?.possession_stats?.["FOULS PER POSSESSION"]
					?.fouls_per_possession_volatility,
			extra: "-",
		},

		/***************************OTHER VARIABLES****************************/
		{
			stat: "",
			category: "OTHER VARIABLES",
			rank: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "THREE POINT RATE",
			rank: data?.other_variables?.["THREE POINT RATE"]?.three_point_rate_rank,
			statDiff:
				data?.other_variables?.["THREE POINT RATE"]?.three_point_rate_stat,
			Diff: data?.other_variables?.["THREE POINT RATE"]?.three_point_rate_diff,
			PA: data?.other_variables?.["THREE POINT RATE"]
				?.three_point_rate_points_added,
			volatility:
				data?.other_variables?.["THREE POINT RATE"]
					?.three_point_rate_volatility,
			extra: "-",
		},
		{
			stat: "6",
			category: "THREE POINT EFFICIENCY",
			rank: data?.other_variables?.["THREE POINT EFFICIENCY"]
				?.three_point_efficiency_rank,
			statDiff:
				data?.other_variables?.["THREE POINT EFFICIENCY"]
					?.three_point_efficiency_stat,
			Diff: data?.other_variables?.["THREE POINT EFFICIENCY"]
				?.three_point_efficiency_diff,
			PA: data?.other_variables?.["THREE POINT EFFICIENCY"]
				?.three_point_efficiency_points_added,
			volatility:
				data?.other_variables?.["THREE POINT EFFICIENCY"]
					?.three_point_efficiency_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "TWO POINT RATE",
			rank: data?.other_variables?.["TWO POINT RATE"]?.two_point_rate_rank,
			statDiff: data?.other_variables?.["TWO POINT RATE"]?.two_point_rate_stat,
			Diff: data?.other_variables?.["TWO POINT RATE"]?.two_point_rate_diff,
			PA: data?.other_variables?.["TWO POINT RATE"]
				?.two_point_rate_points_added,
			volatility:
				data?.other_variables?.["TWO POINT RATE"]?.two_point_rate_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "FASTBREAK EFFICIENCY",
			rank: data?.other_variables?.["FASTBREAK EFFICIENCY"]
				?.fastbreak_efficiency_rank,
			statDiff:
				data?.other_variables?.["FASTBREAK EFFICIENCY"]
					?.fastbreak_efficiency_stat,
			Diff: data?.other_variables?.["FASTBREAK EFFICIENCY"]
				?.fastbreak_efficiency_diff,
			PA: data?.other_variables?.["FASTBREAK EFFICIENCY"]
				?.fastbreak_efficiency_points_added,
			volatility:
				data?.other_variables?.["FASTBREAK EFFICIENCY"]
					?.fastbreak_efficiency_volatility,
			extra: "-",
		},
		{
			stat: "4",
			category: "POINTS IN THE PAINT",
			rank: data?.other_variables?.["POINTS IN THE PAINT"]
				?.points_in_the_paint_rank,
			statDiff:
				data?.other_variables?.["POINTS IN THE PAINT"]
					?.points_in_the_paint_stat,
			Diff: data?.other_variables?.["POINTS IN THE PAINT"]
				?.points_in_the_paint_diff,
			PA: data?.other_variables?.["POINTS IN THE PAINT"]
				?.points_in_the_paint_points_added,
			volatility:
				data?.other_variables?.["POINTS IN THE PAINT"]
					?.points_in_the_paint_volatility,
			extra: "-",
		},
		{
			stat: "4",
			category: "4TH QTR SCORING MARGIN",
			rank: data?.other_variables?.["4TH QTR SCORING MARGIN"]
				?.fourth_quarter_scoring_margin_rank,
			statDiff:
				data?.other_variables?.["4TH QTR SCORING MARGIN"]
					?.fouls_per_possession_stat,
			Diff: data?.other_variables?.["4TH QTR SCORING MARGIN"]
				?.fourth_quarter_scoring_margin_diff,
			PA: data?.other_variables?.["4TH QTR SCORING MARGIN"]
				?.fourth_quarter_scoring_margin_added,
			volatility:
				data?.other_variables?.["4TH QTR SCORING MARGIN"]
					?.fourth_quarter_scoring_margin_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "PACE OF PLAY AVE",
			rank: data?.other_variables?.["PACE OF PLAY AVE"]
				?.pace_of_play_average_rank,
			statDiff:
				data?.other_variables?.["PACE OF PLAY AVE"]?.pace_of_play_average_stat,
			Diff: data?.other_variables?.["PACE OF PLAY AVE"]
				?.pace_of_play_average_diff,
			PA: data?.other_variables?.["PACE OF PLAY AVE"]
				?.pace_of_play_average_points_added,
			volatility:
				data?.other_variables?.["PACE OF PLAY AVE"]
					?.pace_of_play_average_volatility,
			extra: "-",
		},

		/***************************OPPONENT SCORING INFO****************************/
		{
			rank: "",
			category: "OPPONENT SCORING INFO",
			stat: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "PTS GIVEN UP AWAY",
			rank: data?.opponent_scoring_info?.["PTS GIVEN UP AWAY"]
				?.points_given_up_away_rank,
			statDiff:
				data?.opponent_scoring_info?.["PTS GIVEN UP AWAY"]
					?.points_given_up_away_stat,
			Diff: data?.opponent_scoring_info?.["PTS GIVEN UP AWAY"]
				?.points_given_up_away_diff,
			PA: data?.opponent_scoring_info?.["PTS GIVEN UP AWAY"]
				?.points_given_up_away_points_added,
			volatility:
				data?.opponent_scoring_info?.["PTS GIVEN UP AWAY"]
					?.points_given_up_away_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVE PPG LAST 3 GAMES",
			rank: data?.opponent_scoring_info?.["AVE PPG GIVEN UP LAST 3 GAMES"]
				?.ave_ppg_given_up_last_3_games_rank,
			statDiff:
				data?.opponent_scoring_info?.["AVE PPG GIVEN UP LAST 3 GAMES"]
					?.ave_ppg_given_up_last_3_games_stat,
			Diff: data?.opponent_scoring_info?.["AVE PPG GIVEN UP LAST 3 GAMES"]
				?.ave_ppg_given_up_last_3_games_diff,
			PA: data?.opponent_scoring_info?.["AVE PPG GIVEN UP LAST 3 GAMES"]
				?.ave_ppg_given_up_last_3_games_points_added,
			volatility:
				data?.opponent_scoring_info?.["AVE PPG GIVEN UP LAST 3 GAMES"]
					?.ave_ppg_given_up_last_3_games_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "OPP FLOOR %",
			rank: data?.opponent_scoring_info?.["OPP FLOOR %"]
				?.opponent_floor_percentage_rank,
			statDiff:
				data?.opponent_scoring_info?.["OPP FLOOR %"]
					?.opponent_floor_percentage_stat,
			Diff: data?.opponent_scoring_info?.["OPP FLOOR %"]
				?.opponent_floor_percentage_diff,
			PA: data?.opponent_scoring_info?.["OPP FLOOR %"]
				?.opponent_floor_percentage_points_added,
			volatility:
				data?.opponent_scoring_info?.["OPP FLOOR %"]
					?.opponent_floor_percentage_volatility,
			extra: "-",
		},
		/*********************OPPONENT SHOOTING********************/
		{
			rank: "",
			category: "OPPONENT SHOOTING",
			stat: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "EFFECTIVE FIELD GOAL %",
			rank: data?.opponent_shooting?.["EFFECTIVE FIELD GOAL %"]
				?.opponent_effective_field_goal_percentage_rank,
			statDiff:
				data?.opponent_shooting?.["EFFECTIVE FIELD GOAL %"]
					?.opponent_effective_field_goal_percentage_stat,
			Diff: data?.opponent_shooting?.["EFFECTIVE FIELD GOAL %"]
				?.opponent_effective_field_goal_percentage_diff,
			PA: data?.opponent_shooting?.["EFFECTIVE FIELD GOAL %"]
				?.opponent_effective_field_goal_percentage_points_added,
			volatility:
				data?.opponent_shooting?.["EFFECTIVE FIELD GOAL %"]
					?.opponent_effective_field_goal_percentage_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "OPP FASTBREAK EFF",
			rank: data?.opponent_shooting?.["OPP FASTBREAK EFF"]
				?.opponent_fastbreak_efficiency_rank,
			statDiff:
				data?.opponent_shooting?.["OPP FASTBREAK EFF"]
					?.opponent_fastbreak_efficiency_stat,
			Diff: data?.opponent_shooting?.["OPP FASTBREAK EFF"]
				?.opponent_fastbreak_efficiency_diff,
			PA: data?.opponent_shooting?.["OPP FASTBREAK EFF"]
				?.opponent_fastbreak_efficiency_points_added,
			volatility:
				data?.opponent_shooting?.["OPP FASTBREAK EFF"]
					?.opponent_fastbreak_efficiency_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "THREE POINT %",
			rank: data?.opponent_shooting?.["THREE POINT %"]
				?.opponent_three_point_percentage_rank,
			statDiff:
				data?.opponent_shooting?.["THREE POINT %"]
					?.opponent_three_point_percentage_stat,
			Diff: data?.opponent_shooting?.["THREE POINT %"]
				?.opponent_three_point_percentage_diff,
			PA: data?.opponent_shooting?.["THREE POINT %"]
				?.opponent_three_point_percentage_points_added,
			volatility:
				data?.opponent_shooting?.["THREE POINT %"]
					?.opponent_three_point_percentage_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "TWO POINT %",
			rank: data?.opponent_shooting?.["TWO POINT %"]
				?.opponent_two_point_percentage_rank,
			statDiff:
				data?.opponent_shooting?.["TWO POINT %"]
					?.opponent_two_point_percentage_stat,
			Diff: data?.opponent_shooting?.["TWO POINT %"]
				?.opponent_two_point_percentage_diff,
			PA: data?.opponent_shooting?.["TWO POINT %"]
				?.opponent_two_point_percentage_points_added,
			volatility:
				data?.opponent_shooting?.["TWO POINT %"]
					?.opponent_two_point_percentage_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "BLOCK %",
			rank: data?.opponent_shooting?.["BLOCK %"]
				?.opponent_block_percentage_rank,
			statDiff:
				data?.opponent_shooting?.["BLOCK %"]?.opponent_block_percentage_stat,
			Diff: data?.opponent_shooting?.["BLOCK %"]
				?.opponent_block_percentage_diff,
			PA: data?.opponent_shooting?.["BLOCK %"]
				?.opponent_block_percentage_points_added,
			volatility:
				data?.opponent_shooting?.["BLOCK %"]
					?.opponent_block_percentage_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "TRUE SHOOTING %",
			rank: data?.opponent_shooting?.["TRUE SHOOTING %"]
				?.opponent_true_shooting_percentage_rank,
			statDiff:
				data?.opponent_shooting?.["TRUE SHOOTING %"]
					?.opponent_true_shooting_percentage_stat,
			Diff: data?.opponent_shooting?.["TRUE SHOOTING %"]
				?.opponent_true_shooting_percentage_diff,
			PA: data?.opponent_shooting?.["TRUE SHOOTING %"]
				?.opponent_true_shooting_percentage_points_added,
			volatility:
				data?.opponent_shooting?.["TRUE SHOOTING %"]
					?.opponent_true_shooting_percentage_volatility,
			extra: "-",
		},

		/*********************OPPONENT FREE THROWS********************/
		{
			rank: "",
			category: "OPPONENT FREE THROWS",
			stat: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "FREE THROW ATT PER PLAY",
			rank: data?.opponent_free_throws?.["FREE THROW ATT PER PLAY"]
				?.opponent_free_throw_attempts_per_play_rank,
			statDiff:
				data?.opponent_free_throws?.["FREE THROW ATT PER PLAY"]
					?.opponent_free_throw_attempts_per_play_stat,
			Diff: data?.opponent_free_throws?.["FREE THROW ATT PER PLAY"]
				?.opponent_free_throw_attempts_per_play_diff,
			PA: data?.opponent_free_throws?.["FREE THROW ATT PER PLAY"]
				?.opponent_free_throw_attempts_per_play_points_added,
			volatility:
				data?.opponent_free_throws?.["FREE THROW ATT PER PLAY"]
					?.opponent_free_throw_attempts_per_play_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "AVE PTS GIVEN UP",
			rank: data?.opponent_free_throws?.["AVE PTS GIVEN UP"]
				?.opponent_average_points_given_up_rank,
			statDiff:
				data?.opponent_free_throws?.["AVE PTS GIVEN UP"]
					?.opponent_average_points_given_up_stat,
			Diff: data?.opponent_free_throws?.["AVE PTS GIVEN UP"]
				?.opponent_average_points_given_up_diff,
			PA: data?.opponent_free_throws?.["AVE PTS GIVEN UP"]
				?.opponent_average_points_given_up_points_added,
			volatility:
				data?.opponent_free_throws?.["AVE PTS GIVEN UP"]
					?.opponent_average_points_given_up_volatility,
			extra: "-",
		},
		/*********************OPPONENT REBOUNDING********************/
		{
			rank: "",
			category: "OPPONENT REBOUNDING",
			stat: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "OFFENSIVE REBOUNDING %",
			rank: data?.opponent_rebounding?.["OFFENSIVE REBOUNDING %"]
				?.opponent_offensive_rebounding_percentage_rank,
			statDiff:
				data?.opponent_rebounding?.["OFFENSIVE REBOUNDING %"]
					?.opponent_offensive_rebounding_percentage_stat,
			Diff: data?.opponent_rebounding?.["OFFENSIVE REBOUNDING %"]
				?.opponent_offensive_rebounding_percentage_diff,
			PA: data?.opponent_rebounding?.["OFFENSIVE REBOUNDING %"]
				?.opponent_offensive_rebounding_percentage_points_added,
			volatility:
				data?.opponent_rebounding?.["OFFENSIVE REBOUNDING %"]
					?.opponent_offensive_rebounding_percentage_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "DEFENSIVE REBOUND %",
			rank: data?.opponent_rebounding?.["DEFENSIVE REBOUND %"]
				?.opponent_defensive_rebound_percentage_rank,
			statDiff:
				data?.opponent_rebounding?.["DEFENSIVE REBOUND %"]
					?.opponent_defensive_rebound_percentage_stat,
			Diff: data?.opponent_rebounding?.["DEFENSIVE REBOUND %"]
				?.opponent_defensive_rebound_percentage_diff,
			PA: data?.opponent_rebounding?.["DEFENSIVE REBOUND %"]
				?.opponent_defensive_rebound_percentage_points_added,
			volatility:
				data?.opponent_rebounding?.["DEFENSIVE REBOUND %"]
					?.opponent_defensive_rebound_percentage_volatility,
			extra: "-",
		},
		/*********************OPPONENT POSSESSION STATS********************/
		{
			rank: "",
			category: "OPPONENT POSSESSION STATS",
			stat: "",
			statDiff: "",
			Diff: "",
			PA: "",
			volatility: "",
			extra: "",
		},
		{
			stat: "-",
			category: "EFFECTIVE POSSESSION RATIO",
			rank: data?.opponent_possession_stats?.["EFFECTIVE POSSESSION RATIO"]
				?.opponent_effective_possession_ratio_rank,
			statDiff:
				data?.opponent_possession_stats?.["EFFECTIVE POSSESSION RATIO"]
					?.opponent_effective_possession_ratio_stat,
			Diff: data?.opponent_possession_stats?.["EFFECTIVE POSSESSION RATIO"]
				?.opponent_effective_possession_ratio_diff,
			PA: data?.opponent_possession_stats?.["EFFECTIVE POSSESSION RATIO"]
				?.opponent_effective_possession_ratio_points_added,
			volatility:
				data?.opponent_possession_stats?.["EFFECTIVE POSSESSION RATIO"]
					?.opponent_effective_possession_ratio_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "OPP TURNOVER PER POSSESSION",
			rank: data?.opponent_possession_stats?.["OPP TURNOVER PER POSSESSION"]
				?.opponent_turnovers_per_possession_rank,
			statDiff:
				data?.opponent_possession_stats?.["OPP TURNOVER PER POSSESSION"]
					?.opponent_turnovers_per_possession_stat,
			Diff: data?.opponent_possession_stats?.["OPP TURNOVER PER POSSESSION"]
				?.opponent_turnovers_per_possession_diff,
			PA: data?.opponent_possession_stats?.["OPP TURNOVER PER POSSESSION"]
				?.opponent_turnovers_per_possession_points_added,
			volatility:
				data?.opponent_possession_stats?.["OPP TURNOVER PER POSSESSION"]
					?.opponent_turnovers_per_possession_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "OPP ASSIST-TO RATIO",
			rank: data?.opponent_possession_stats?.["OPP ASSIST-TO RATIO"]
				?.opponent_assist_to_ratio_rank,
			statDiff:
				data?.opponent_possession_stats?.["OPP ASSIST-TO RATIO"]
					?.opponent_assist_to_ratio_stat,
			Diff: data?.opponent_possession_stats?.["OPP ASSIST-TO RATIO"]
				?.opponent_assist_to_ratio_diff,
			PA: data?.opponent_possession_stats?.["OPP ASSIST-TO RATIO"]
				?.opponent_assist_turnover_to_points_added,
			volatility:
				data?.opponent_possession_stats?.["OPP ASSIST-TO RATIO"]
					?.opponent_assist_to_ratio_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "OPP PTS IN THE PAINT",
			rank: data?.opponent_possession_stats?.["OPP PTS IN THE PAINT"]
				?.opponent_points_in_the_paint_rank,
			statDiff:
				data?.opponent_possession_stats?.["OPP PTS IN THE PAINT"]
					?.opponent_points_in_the_paint_stat,
			Diff: data?.opponent_possession_stats?.["OPP PTS IN THE PAINT"]
				?.opponent_points_in_the_paint_diff,
			PA: data?.opponent_possession_stats?.["OPP PTS IN THE PAINT"]
				?.opponent_points_in_the_paint_points_added,
			volatility:
				data?.opponent_possession_stats?.["OPP PTS IN THE PAINT"]
					?.opponent_points_in_the_paint_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "OPP ASSIST-FG RATIO",
			rank: data?.opponent_possession_stats?.["OPP ASSIST-FG RATIO"]
				?.opponent_assist_fg_ratio_rank,
			statDiff:
				data?.opponent_possession_stats?.["OPP ASSIST-FG RATIO"]
					?.opponent_assist_fg_ratio_stat,
			Diff: data?.opponent_possession_stats?.["OPP ASSIST-FG RATIO"]
				?.opponent_assist_fg_ratio_diff,
			PA: data?.opponent_possession_stats?.["OPP ASSIST-FG RATIO"]
				?.opponent_assist_fg_ratio_points_added,
			volatility:
				data?.opponent_possession_stats?.["OPP ASSIST-FG RATIO"]
					?.opponent_assist_fg_ratio_volatility,
			extra: "-",
		},
		{
			stat: "-",
			category: "TOTAL",
			rank: "-",
			statDiff:
				"-",
			Diff: "-",
			PA: data?.Totals?.Points_added_total,
			volatility: data?.Totals?.Total_volality,
			extra: "-",
		},

		// Add more rows as needed
	];
};

export default updateTableData;

// //APPROACH 2

// data.js

// Utility function to format values
// const formatValue = (value) => {
// 	if (typeof value === "number") {
// 		return value % 1 === 0 ? value : value.toFixed(2);
// 	}
// 	return value !== null && value !== undefined ? value : "-";
// };

// // Function to update and filter the table data
// const updateTableData = (data) => {
// 	const result = [
// 		{
// 			stat: "STAT WT",
// 			category: "CATEGORY",
// 			rank: "RANK",
// 			statDiff: "STAT",
// 			Diff: "DIFF",
// 			PA: "POINTS ADDED",
// 			volatility: "VOLATILITY",
// 		},
// 	];

// 	const unwantedEntries = [
//         "AVERAGES",
// 		"EXTRA",
// 		"TOTALS",
// 		"ROW 10 DATA",
// 		"ROW 7 DATA",
// 		"POINTS ADDED TOTAL",
// 		"TOTAL VOLALITY",
// 	];

// 	for (const category in data) {
// 		const categoryData = data[category];
// 		if (!unwantedEntries.includes(category.replace(/_/g, " ").toUpperCase())) {
// 			result.push({
// 				stat: "",
// 				category: category.replace(/_/g, " ").toUpperCase(),
// 				rank: "",
// 				statDiff: "",
// 				diff: "",
// 				PA: "",
// 				volatility: "",
// 			});
// 		}
// 		for (const statName in categoryData) {
// 			const statData = categoryData[statName];

// 			// Convert statName to snake_case and handle percentage sign
// 			const formattedStatName = statName
// 				.toLowerCase()
// 				.replace(/ /g, "_")
// 				.replace(/%/g, "percentage")
//                 .replace(/-/g,"_")
//                 .replace(/opp/g, "opponent")
//                 const prefix = category.startsWith("opponent") ? "opponent_" : "";
//                 const prefixedFormattedStatName = prefix + formattedStatName;

//                 console.log(prefixedFormattedStatName)
// 			const formattedStat = statName.replace(/_/g, " ").toUpperCase();

// 			// Filter out unwanted entries
// 			if (!unwantedEntries.includes(formattedStat)) {
// 				result.push({
// 					stat: "-",
// 					category: formattedStat || "-",
// 					rank: statData[`${formattedStatName}_rank`] || "-",
// 					statDiff: statData[`${formattedStatName}_stat`] || "-",
// 					diff: statData[`${formattedStatName}_diff`] || "-",
// 					PA: statData[`${formattedStatName}_points_added`] || "-",
// 					volatility: statData[`${formattedStatName}_volatility`] || "-",
// 				});
// 			}
// 		}
// 	}
// 	console.log(result);
// 	return result;
// };

// export default updateTableData;
