import React from "react";
import { Typography } from "@mui/material";
import Expand from "./Expand";
import Dropdown from "./Dropdown";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  BettingSignals,
  MatchupAnalysis,
  GamePrediction,
  VegasLines,
  RecordStats,
  TeamAverageLines,
  CustomOutputTable,
  MiniMatchTable,
  LiveProjection,
  HorizontalSlideBar,
  PreviousH2HMatch,
  PreviousFiveGames,
  GameProbabilities,
  BettingTrendsSpread,
  BettingTrendsTotal,
  BettingTrendsMoneyLine,
  NextGameType,
  OddLineMovement,
} from "./TableOne";
import GameContext from "./GameContext";
import GameVariables from "./GameVariables";
import PickBiasDropdown from "./PickBiasDropdown";
import logo from "../images/logo.png";
import ExpandRight from "./ExpandRight";
import Box from "@mui/material/Box";
import { Blocks } from "react-loader-spinner";
import debounce from "lodash.debounce";
import Schedule from "./Schedule";
import InjuryReport from "./InjuryReport";
import RoasterComparisionCustom from "./RoasterComparisionCustom";
import { Routes, Route, Link } from "react-router-dom";
function Main() {
  const [open, setOpen] = React.useState(false); // Setting the state for expanding for left side previous match score
  const [open1, setOpen1] = React.useState(false); // Setting the state for expanding for right side previous match score
  const [match1, setMatch1] = React.useState("Boston"); // handling the 'home' state
  const [match2, setMatch2] = React.useState("Dallas"); // handling the 'away' state
  const [pickBias, setPickBias] = React.useState("NO BIAS: AVE GAME");
  const [impactLeft, setImpactLeft] = React.useState("NO IMPACT");
  const [impactRight, setImpactRight] = React.useState("NO IMPACT");
  const [gamedate, setgamedate] = React.useState("2024-05-19");
  const [homeData, setHomeData] = React.useState(null);
  const [awayData, setAwayData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [loadingSchedule, setLoadingSchedule] = React.useState(true);
  const [games, setGames] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [filteredGames, setFilteredGames] = React.useState([]);
  const [homePlayersData, setHomePlayersData] = React.useState([]);
  const [awayPlayersData, setAwayPlayersData] = React.useState([]);
  const [selectedPlayers, setSelectedPlayers] = React.useState({
    homePlayer1: "",
    homePlayer2: "",
    homePlayer3: "",
    homePlayer4: "",
    awayPlayer1: "",
    awayPlayer2: "",
    awayPlayer3: "",
    awayPlayer4: "",
  });

  const fetchPlayerLists = async () => {
    if (!match1 || !match2) return;

    setLoading(true);
    try {
      const [homeResponse, awayResponse] = await Promise.all([
        fetch(`https://degeneratesodds.sataware.dev:5000/api/playerlist/${match1}`),
        fetch(`https://degeneratesodds.sataware.dev:5000/api/playerlist/${match2}`),
      ]);

      setHomePlayersData(await homeResponse.json());
      setAwayPlayersData(await awayResponse.json());
      setSelectedPlayers((prevState) => ({ ...prevState }));
    } catch (error) {
      console.error("Error fetching player lists data:", error);
    } finally {
      setLoading(false);
    }
    setLoading(true);
  };

  const fetchData = async () => {
    if (!match1 || !match2 || !gamedate) return;

    setLoading(true);
    try {
      const params = new URLSearchParams({
        game1: match1,
        game2: match2,
        sport: "NBA",
        game_script: pickBias,
        impact1: impactLeft,
        impact2: impactRight,
        "game-date": gamedate,
      }).toString();

      const response = await fetch(`https://degeneratesodds.sataware.dev:5000/api/main_category?${params}`);
      setLoading(true);
      const result = await response.json();

      setHomeData(result?.result_home);
      setAwayData(result?.result_away);
      setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setLoadingSchedule(true);
    fetch("https://degeneratesodds.sataware.dev:5000/api/schedules")
      .then((response) => response.json())
      .then((data) => {
        setGames(data);
        setSelectedDate(data[0]?.["UTC time"] || "");
        setLoadingSchedule(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoadingSchedule(false);
      });
  }, []);
  React.useEffect(() => {
    const filtered = games.filter((game) => game["UTC time"] === selectedDate);
    setFilteredGames(filtered);
    if (filtered.length > 0) {
      setMatch1(filtered[0].home);
      setMatch2(filtered[0].away);
      setgamedate(filtered[0].date);
    }
  }, [selectedDate, games]);

  React.useEffect(() => {
    fetchPlayerLists();
    fetchData();
  }, [match1, match2, pickBias, impactLeft, impactRight, gamedate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  


  const handleMatch1Change = (value) => {
    setMatch1(value);
    setLoading(true);
    console.log("Match 1 selected:", value);
  };

  const handleMatch2Change = (value) => {
    setMatch2(value);
    setLoading(true);
    console.log("Match 2 selected:", value);
  };
  const pickBiasChange = (value) => {
    setPickBias(value);
    setLoading(true);
    console.log("pick bias selected:", value);
  };
  const impactLeftChange = (value) => {
    setImpactLeft(value);
    setLoading(true);
    console.log(" impact left selected:", value);
  };
  const impactRightChange = (value) => {
    setImpactRight(value);
    setLoading(true);
    console.log("impact right  selected:", value);
  };

  // handling the expanding/collapsing state for left side
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // handling the expanding/collapsing state for right side
  const toggleDrawerRight = () => {
    setOpen1(!open1);
  };
  // adding conditional width of MAIN component when one or both previous match score opened
  var boxWidth = "";
  if (open && !open1) {
    boxWidth = "1300px";
  } else if (!open && open1) {
    boxWidth = "1300px";
  } else {
    boxWidth = "";
  }


  // const tableData = schedules.map((schedule) => [
  //   schedule.date,
  // schedule.home,
  // schedule.away,
  //   schedule.hotness_score,
  //   schedule.location,
  //   schedule.matchup,
  //   schedule.time,
  // ]);

  return (
    <div style={{ width: open || open1 ? "2100px" : "" }}>

      {/******TITLE*****/}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div className=""></div>
        <Typography
          align="center"
          variant="h5"
          sx={{ mt: 2, color: "#ffce19" }}
        >
           <Link to = "/">
         <img src={logo} width="200px" alt="logo" /></Link>
        </Typography>

      </Box>
      <div style={{position:"absolute", right:"1%",top:"5%"}}>      <Link to="/analytics"  state={{ data, match1, match2 }}><Button  sx={{ mr:2, backgroundColor: "#17A4B8", color: "white" }}
          size="small" >Analytics Report</Button></Link>

      <Link to="/props" state={{ homePlayersData, match2 }}><Button  sx={{ mr:2, backgroundColor: "#17A4B8", color: "white" }}
          size="small" >Props</Button></Link></div>

      {loadingSchedule ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "15vh",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              marginTop: "10px",
              color: "#ffce19",
              fontWeight: "bold",
            }}
          >
            LOADING SCHEDULES
          </Typography>
          <Blocks
            height="60"
            width="60"
            color="#ffce19"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            visible={true}
          />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "250px",
          }}
        >
          <Schedule
            games={games}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1px 40px",
        }}
      >
        {/******BUTTONS FOR EXPANDING/COLLAPSING SECTIONS*****/}

        {/******BUTTON FOR LEFT TABLE*****/}
        <Button
          onClick={toggleDrawer}
          sx={{ backgroundColor: "#17A4B8", color: "white" }}
          size="small"
        >
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          View Points Table
        </Button>
        {/******MATCH-1 DROPDOWN*****/}
        <Dropdown
          stateName="Home"
          id="dropdown-match-1"
          onSelectionChange={handleMatch1Change}
          defaultValue={match1}
        />
        <Typography variant="h5" style={{ color: "#ffce19" }}>
          V/S
        </Typography>
        {/******MATCH-2 DROPDOWN*******/}
        <Dropdown
          stateName="Away"
          id="dropdown-match-2"
          onSelectionChange={handleMatch2Change}
          defaultValue={match2}
        />
        {/******BUTTON FOR THE RIGHT TABLE*****/}
        <Button
          onClick={toggleDrawerRight}
          sx={{ backgroundColor: "#17A4B8", color: "white" }}
          size="small"
        >
          {open1 ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          View Points Table
        </Button>
      </div>
      {/********************LOADER*********************/}
      {loading  ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "45vh",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              marginTop: "10px",
              color: "#ffce19",
              fontWeight: "bold",
            }}
          >
            LOADING DATA
          </Typography>
          <Blocks
            height="80"
            width="80"
            color="#ffce19"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            visible={true}
          />
        </Box>
      ) : (
        <div style={{ display: data?.error ? "none" : "" }}>
          <div
            style={{
              display: open || open1 ? "flex" : "",
              justifyContent: "space-between",
            }}
          >
            {/*******LEFT EXPANDABLE SECTION (Result_home table)*******/}
            {open && (
              <Expand data={homeData} matchOne={match1} matchTwo={match2} />
            )}
            {/*******ALL OTHER TABLES*******/}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: open && open1 ? "column" : "row",
                  width: boxWidth,
                  marginLeft: open && open1 ? "50px" : 0,
                  alignSelf: open || open1 ? "flex-start" : "",
                  marginTop: open || open1 ? "75px" : "50px",
                }}
              >
                {/******BETTING SIGNALS, MATCHUP ANALYSIS , GAME PREDICTION TABLE*****/}{" "}
                {open && open1 ? (
                  <center>
                    <BettingSignals
                      tableWidth={open && !open1 ? 800 : 500}
                      data={data}
                    />{" "}
                    {/*conditional width style for betting signals table when both the sections opened*/}
                    <br />
                    <MatchupAnalysis
                      tableWidth={open ? 500 : 450}
                      data={data}
                    />
                    <br />
                    <GamePrediction tableWidth={open ? 500 : 450} data={data} />
                  </center>
                ) : (
                  <>
                    <BettingSignals
                      tableWidth={open || open1 ? 700 : 500}
                      data={data}
                    />{" "}
                    {/*conditional width style for betting signals table when either section opened*/}
                    <br />
                    <MatchupAnalysis
                      tableWidth={open ? 500 : 450}
                      data={data}
                    />
                    <br />
                    <GamePrediction tableWidth={open ? 500 : 450} data={data} />
                  </>
                )}
              </div>
              {/***conditional component (vegas lines and game Context) when both the sections or only section opened***/}
              {((open && !open1) || (!open && open1) || (!open && !open1)) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    width: boxWidth,
                    marginLeft: open && open1 ? "50px" : 0,
                    alignSelf: open || open1 ? "flex-start" : "",
                    marginTop: open || open1 ? "75px" : "40px",
                  }}
                >
                  <VegasLines tableWidth={open ? 500 : 450} data={data} />
                  <br />
                  <GameContext tableWidth={open ? 650 : 650} data={data} />
                </div>
              )}
            </div>
            {/*******RIGHT EXPANDABLE SECTION (Result_away table)*******/}
            {open1 && (
              <ExpandRight
                data={awayData}
                matchOne={match1}
                matchTwo={match2}
              />
            )}
          </div>
          {/***conditional component (vegas lines and game Context) NO section opened***/}
          {open && open1 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                width: boxWidth,
                marginLeft: open && open1 ? "50px" : 0,
                alignSelf: open || open1 ? "flex-start" : "",
                marginTop: open || open1 ? "75px" : "20px",
              }}
            >
              <VegasLines tableWidth={open ? 500 : 450} data={data} />
              <br />
              <GameContext tableWidth={open ? 650 : 650} data={data} />
            </div>
          )}
          <center>
            {/***PICK BIAS DROPDOWN AND MINI MATCH TABLE IN ONE ROW***/}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                width: boxWidth,
                columnGap: "190px",
                marginLeft: open && open1 ? "50px" : 0,
                alignSelf: open || open1 ? "flex-start" : "",
                marginTop: open || open1 ? "25px" : "20px",
              }}
            >
              <PickBiasDropdown
                stateName="PICK BIAS"
                id="dropdown-match-3"
                onSelectionChange={pickBiasChange}
                defaultValue={pickBias}
              />
              <MiniMatchTable tableWidth={open ? 500 : 450} data={data} />
            </div>
          </center>
          <br />
          <br />
          {/***TEAM AVERAGE LINES***/}
          <center>
            <TeamAverageLines tableWidth={open ? 900 : 800} data={data} />
          </center>
          <br />
          <center>
            <NextGameType tableWidth={open ? 600 : 500} data={data} />
          </center>
          <br />
          <br />
          {/***RECORD STATS***/}
          <center>
            <RecordStats tableWidth={open ? 900 : 700} data={data} />
          </center>
          <br />
          <br />
          {/***GAME VARIABLES***/}
          <center>
            <GameVariables
              tableWidth={open ? 900 : 700}
              homeData={homeData}
              awayData={awayData}
              matchOne={match1}
              matchTwo={match2}
              onSelectionChangeOne={impactLeftChange}
              onSelectionChangeTwo={impactRightChange}
              defaultOne={impactLeft}
              d
              defaultTwo={impactRight}
            />
          </center>
          <br />
          <br />
          {/***MATCH OUTPUT TABLE***/}
          <center>
            <CustomOutputTable tableWidth={open ? 900 : 700} data={data} />
          </center>
          <br />
          <br />
          {/***Previous H2H MATCHUP TABLE***/}
          <center>
            <PreviousH2HMatch tableWidth={open ? 900 : 700} data={data} />
          </center>
          <br />
          <br />
          {/***Previous 5 game TABLE***/}
          <center>
            <PreviousFiveGames tableWidth={open ? 1550 : 1400} data={data} />
          </center>
          <br />
          <br />
          {/***Horizontal slide bar TABLE***/}
          <center>
            <HorizontalSlideBar tableWidth={open ? 600 : 550} data={data} />
          </center>
          <br />
          <br />
          {/***Live projection TABLE***/}
          <center>
            <LiveProjection tableWidth={open ? 800 : 700} data={data} />
          </center>
          <br />
          <br />
          <center>
            <GameProbabilities tableWidth={open ? 1200 : 1000} data={data} />
          </center>
          <br />
          <br />
          <center>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                width: boxWidth,
                marginLeft: open && open1 ? "50px" : 0,
                alignSelf: open || open1 ? "flex-start" : "",
                marginTop: open || open1 ? "75px" : "50px",
              }}
            >
              <BettingTrendsSpread tableWidth={open ? 600 : 450} data={data} />
              <BettingTrendsTotal tableWidth={open ? 600 : 450} data={data} />
              =
              <BettingTrendsMoneyLine
                tableWidth={open ? 600 : 450}
                data={data}
              />
              <br />
              <br />
            </div>
          </center>
          <br />
          <br />
          <center>
            <OddLineMovement tableWidth={open ? 600 : 500} data={data} />
          </center>
          <br />
          <br />
          <center>
            <RoasterComparisionCustom tableWidth={open ? 1400 : 1250} data={data} matchOne={match1} matchTwo={match2} selectedPlayers={selectedPlayers} awayPlayersData={awayPlayersData}
              homePlayersData={homePlayersData}/>
          </center>
          <br />
          <br />
          <center>
          {(data?.Injury_report?.home_player1 || data?.Injury_report?.home_player2 || data?.Injury_report?.home_player3 || data?.Injury_report?.home_player4 || data?.Injury_report?.away_player1 || data?.Injury_report?.away_player2 || data?.Injury_report?.away_player3 || data?.Injury_report?.away_player4) &&  <InjuryReport
              tableWidth={open ? 1400 : 1250}
              data={data}
              awayPlayersData={awayPlayersData}
              homePlayersData={homePlayersData}
              selectedPlayers={selectedPlayers}
            
            />}
          </center>
          <br />
          <br />
        </div>
      )}
      {/* ******************ERROR HANDLING (NO DATA FOUND)*************** */}
      {data?.error && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textTransform: "uppercase",
              marginTop: "10px",
              color: "#ffce19",
              fontWeight: "bold",
              margin: "50px 0",
            }}
          >
            NO DATA FOUND FOR ABOVE SELECTED TEAMS
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default Main;