import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import logo from "../images/logo.png";
import { Link, useLocation } from 'react-router-dom';
import { AnalyticsReportTable } from './TableOne';


function AnalyticsReport() {
    const location = useLocation();
  const {data, match1,match2} = location.state || {};
  const reports = data?.Reports


   
  return (
    <div style={{color:"white"}}>
             {/******TITLE*****/}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography
          align="center"
          variant="h5"
          sx={{ mt: 2, color: "#ffce19" }}
        >
          <Link to = "/">
          <img src={logo} width="200px" alt="logo" /></Link>
        </Typography>
      </Box>
      <br />

 <br />
      <center>
    <AnalyticsReportTable data={reports} tableWidth={1400} match1={match1} match2={match2} />
    </center>
    </div>
  )
}

export default AnalyticsReport