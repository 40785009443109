import React from "react";
import { Container, Typography } from "@mui/material";
import GameCarousel from "./GameCarousel";
import GameDetails from "./GameDetails";

const Schedule = ({ games, selectedDate, handleDateChange }) => {
  const selectedGame = games.find((game) => game.date === selectedDate);

  return (
    <Container>
      <GameCarousel
        games={games}
        onDateChange={handleDateChange}
        selectedDate={selectedDate}
      />
    </Container>
  );
};

export default Schedule;
