import * as React from "react";
import Box from "@mui/material/Box";
import MatchTable from "./MatchTable";
import "../App.css";

/***************EXPANDABLE PREVIOUS MATCH SCORE TABLE*****************/

export default function TemporaryDrawer({data, matchOne, matchTwo}) {
	const DrawerList = (
		<Box sx={{ width: 700, margin: "20px" }} role="presentation">
			<MatchTable data={data} matchOne={matchOne} matchTwo={matchTwo} />
		</Box>
	);

	return (
		<div>
			<div>{DrawerList}</div>
		</div>
	);
}
