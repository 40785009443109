
import React from 'react'
import { Route,Routes } from 'react-router-dom'
import Main from './components/Main'
import "./App.css";
import AnalyticsReport from './components/AnalyticsReport';
import Props from './components/Props';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/analytics" element={<AnalyticsReport />} />
        <Route path="/props" element={<Props />} />
      </Routes>
    </div>
  )
}

export default App