import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";


// Custom Table for Game Variables

// styling the menu selection box
function GameVariables({ tableWidth, homeData, awayData, matchOne, matchTwo, onSelectionChangeOne,defaultOne,  onSelectionChangeTwo, defaultTwo }) {

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	// Handling the IMPACTS dropdown
	const impacts = [
		"NO IMPACT",
		"MINOR IMPACT",
		"KEY PLAYERS HURT",
		"ALL STAR OUT",
	];

	const handleChange1 = (event) => {
		const {
			target: { value },
		} = event;
		onSelectionChangeOne(value)
	};

	const handleChange2 = (event) => {
		const {
			target: { value },
		} = event;
		onSelectionChangeTwo(value)
	};
	// Custom Styling for Table Cell and Table Head
	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			textAlign: "center",
			color: "#ffce19",
			fontSize: 12,
			textTransform:"uppercase"
		},
		[`&.${tableCellClasses.body}`]: {
			color: "#ccc",
			fontSize: 11,
			Width: 50, // Adjust the width as needed
			textAlign: "center",
			padding: "11px 8px",
			// Center align all cells by default
		},
		"&.average": {
			color: "#ffce19",
			border: "2px solid #ffce19",
		},
		"&.offensive": {
			fontWeight: "bolder",
		},
	}));
	return (
		<>
			<TableContainer
				component={Paper}
				style={{
					margin: "0 20px",
					backgroundColor: "transparent",
					color: "white !important",
					width: tableWidth,
					border: "none",
				}}
			>
				<Typography
					variant="subtitle1"
					sx={{
						textTransform: "uppercase",
						marginTop: "10px",
						color: "#fff",
						fontSize: 18,
					}}
				>
					<center>GAME VARIABLES</center>
				</Typography>
				<Table>
					{/***************TABLE HEADER*****************/}
					<TableHead>
						<TableRow className="highlight">
						<StyledTableCell></StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell className="highlight">{matchOne}</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>{matchTwo}</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
					</TableHead>
					{/***************TABLE DATA*****************/}
					<TableBody>
						<TableRow>
						
						<StyledTableCell>{homeData?.Extra?.Row_7_data?.["S39"].toFixed(2)}</StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell className="average">{homeData?.Extra?.Row_7_data?.["OFFENSIVE MOMENTUM"].toFixed(2)}</StyledTableCell>
							<StyledTableCell className="offensive">
								OFFENSIVE MOMENTUM
							</StyledTableCell>
							<StyledTableCell className="average">
							{awayData?.Extra?.Row_7_data?.["OFFENSIVE MOMENTUM"].toFixed(2)}
							</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_7_data?.["AF39"].toFixed(2)}</StyledTableCell>
							
						</TableRow>
						<TableRow>
						
						<StyledTableCell>{homeData?.Extra?.Row_7_data?.["S40"].toFixed(2)}</StyledTableCell>
						<StyledTableCell>{homeData?.Extra?.Row_7_data?.["Row_40_difference__t-40"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>{homeData?.Extra?.Row_7_data?.["LAST 5 GAMES PPG"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>LAST 5 GAMES PPG</StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_7_data?.["LAST 5 GAMES PPG"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_7_data?.["Row_40_difference__t-40"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_7_data?.["AF40"].toFixed(2)}</StyledTableCell>
							
						</TableRow>
						<TableRow>
						<StyledTableCell></StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell>{homeData?.Extra?.Row_7_data?.["OFFENSIVE PPG FOR SEASON"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>OFFENSIVE PPG FOR SEASON</StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_7_data?.["OFFENSIVE PPG FOR SEASON"].toFixed(2)}</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
						<TableRow>
						
						<StyledTableCell>{homeData?.Extra?.Row_10_data?.["S42"].toFixed(2)}</StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell className="average">{homeData?.Extra?.Row_10_data?.["DEFENSIVE MOMENTUM"].toFixed(2)}</StyledTableCell>
							<StyledTableCell className="offensive">
								DEFENSIVE MOMENTUM
							</StyledTableCell>
							<StyledTableCell className="average">
							{awayData?.Extra?.Row_10_data?.["DEFENSIVE MOMENTUM"].toFixed(2)}
							</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_10_data?.["AF42"].toFixed(2)}</StyledTableCell>
							
						</TableRow>
						<TableRow>
						
						<StyledTableCell>{homeData?.Extra?.Row_10_data?.["S43"].toFixed(2)}</StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell>{homeData?.Extra?.Row_10_data?.["DEFENSE PTS ALLOWED LAST 5 GAMES"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>
								DEFENSE PTS ALLOWED LAST 5 GAMES
							</StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_10_data?.["DEFENSE PTS ALLOWED LAST 5 GAMES"].toFixed(2)}</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_10_data?.["AF43"].toFixed(2)}</StyledTableCell>
							
						</TableRow>
						<TableRow>
						<StyledTableCell></StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell>{awayData?.Extra?.Row_10_data?.["DEFENSE PPG ALLOWED FOR SEASON"].toFixed(2)}</StyledTableCell>
							<StyledTableCell>DEFENSE PPG ALLOWED FOR SEASON</StyledTableCell>
						
						<StyledTableCell>{homeData?.Extra?.Row_10_data?.["DEFENSE PPG ALLOWED FOR SEASON"].toFixed(2)}</StyledTableCell>
						<StyledTableCell></StyledTableCell>
						<StyledTableCell></StyledTableCell>
						</TableRow>
						<TableRow>
						<StyledTableCell></StyledTableCell>
						<StyledTableCell></StyledTableCell>
							<StyledTableCell>
								
							{homeData?.Extra?.Row_10_data?.["INJURY/PLAYER LOSS IMPACT"]}
							</StyledTableCell>
							<StyledTableCell className="offensive">
								INJURY / PLAYER LOSS IMPACT
							</StyledTableCell>
							<StyledTableCell>
							{awayData?.Extra?.Row_10_data?.["INJURY/PLAYER LOSS IMPACT"]}
							</StyledTableCell>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

export default GameVariables;
