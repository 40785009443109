import React from "react";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Typography,
} from "@mui/material";
// Custom Table for Team Average Lines
const CustomTableAvgLines = ({
	title,
	headers,
	data,
	tableWidth,
	border,
	borderCell,
	paddingCell,
}) => {
	return (
		<TableContainer
			component={Paper}
			style={{
				margin: "0 20px",
				backgroundColor: "transparent",
				color: "white",
				width: tableWidth,
				border: border,
			}}
		>
			<Typography
				variant="subtitle1"
				sx={{ textTransform: "uppercase", marginTop: "10px" }}
			>
				<center>{title}</center>
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						{headers.map((header, index) => (
							<TableCell
								key={index}
								sx={{
									textAlign: "center",
									color: "#ffce19",
									fontSize: "12px",
									textTransform: "uppercase",
									border: borderCell,
								}}
							>
								{header}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row, rowIndex) => (
						<TableRow key={rowIndex} className={rowIndex === 2 ? "bottomBorder" : ""}>
							{row.map((cell, index) => (
								<TableCell
									key={index}
									sx={{
										textAlign: "center",
										color:
											cell === "115" ||
											cell === "+12.0" ||
											cell === "-12.0" ||
											cell === "126.9" ||
											index === 0 || index === 1 || index === 2 ||((rowIndex===1 || rowIndex===4)&&index===4)
												? "#ffce19"
												: index === 10 || (rowIndex===6 && index===5)
												? "#ff0000" // Red color for "-2.4" or "3"
												: "#cccccc",

										fontSize: "12px",
										border:
											cell === "115" || cell === "126.9"
												? "2px solid #ffce19"
												: (rowIndex === 1 || rowIndex ===4) && index === 2
												? "1px solid #ffce19"
												: borderCell,
										padding: paddingCell,
									}}
								>
								{cell}

								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default CustomTableAvgLines;
