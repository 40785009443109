import * as React from "react";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "../App.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name, selectedName, theme) {
	return {
		fontWeight:
			selectedName === name
				? theme.typography.fontWeightMedium
				: theme.typography.fontWeightRegular,
		borderColor: "#ffce19",
	};
}

export default function SingleSelect({
	stateName,
	id,
	onSelectionChange,
	defaultValue,
	options, // Player options from homePlayersData
}) {
	const theme = useTheme();
	const [selectedName, setSelectedName] = React.useState(defaultValue || "");

	const handleChange = (event) => {
		const { value } = event.target;
		setSelectedName(value);
		onSelectionChange(value);
	};

	return (
		<div>
			<FormControl
				variant="standard"
				sx={{ m: 1, width: 200, outline: "none", border: "none" }}
				color="warning"
			>
				<InputLabel id={id} color="warning" sx={{ color: "#fff" }}>
					{stateName}
				</InputLabel>
				<Select
					labelId={id}
					id="demo-single-name"
					value={defaultValue}
					onChange={handleChange}
					MenuProps={MenuProps}
					sx={{
						borderBottom: "1px solid #ffce19",
						color: "#fff",
						"& .MuiSvgIcon-root": {
							color: "#ffce19",
						},
					}}
				>
					{options.map((option) => (
						<MenuItem
							key={option.player} // Unique identifier for each player
							value={option.player} // Player's name as value
							style={getStyles(option.player, selectedName, theme)}
						>
							
							{option.player}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
}
