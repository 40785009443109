import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography
} from "@mui/material";

const RoasterComparisionCustom = ({
  tableWidth,
  data,
  matchOne,
  matchTwo,
  selectedPlayers,
  awayPlayersData,
  homePlayersData
}) => {


  // Check if Roster_info is defined
  if (!data?.Roster_info) {
    console.error("Roster_info is undefined");
    return null;
  }

  return (
    <TableContainer
      component={Paper}
      style={{
        margin: "0 20px",
        backgroundColor: "transparent",
        color: "white",
        border: "1px solid #cccccc",
        width: tableWidth
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ textTransform: "uppercase", marginTop: "10px" }}
      >
        <center>Roster comparision</center>
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "11px", width: 100 }}>
              {matchOne}(Players)
            </TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>AGE</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>DBPM</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>OBPM</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>TOTAL VALUE</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>VORP</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>POSITION</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "11px", width: 100 }}>
              {matchTwo}(Players)
            </TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>AGE</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>DBPM</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>OBPM</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>TOTAL VALUE</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>VORP</TableCell>
            <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>POSITION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.Roster_info[0]?.Home_team.map((homePlayerData, index) => {
            // Access the corresponding away player data using the same index
            const awayPlayerData = data?.Roster_info[1]?.away_team[index];

            return (
              <TableRow key={index}>
                {/* Home Team Player Details */}
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 100 }}>
                  <center>{homePlayerData?.player_name || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{homePlayerData?.Age || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 60 }}>
                  <center>{homePlayerData?.DBPM_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{homePlayerData?.OBPM_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{homePlayerData?.Total_value_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 60 }}>
                  <center>{homePlayerData?.Vorp_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{homePlayerData?.position || "N/A"}</center>
                </TableCell>
                {/* Away Team Player Details */}
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 100 }}>
                  <center>{awayPlayerData?.player_name || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{awayPlayerData?.Age || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 60 }}>
                  <center>{awayPlayerData?.DBPM_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{awayPlayerData?.OBPM_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{awayPlayerData?.Total_value_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 60 }}>
                  <center>{awayPlayerData?.Vorp_percentage?.toFixed(2) || "N/A"}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "11px", width: 40 }}>
                  <center>{awayPlayerData?.position || "N/A"}</center>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RoasterComparisionCustom;


