import CustomTable from "./CustomTable";
import React from "react";
import CustomTableAvgLines from "./CustomTableAvgLines";
import OutputTable from "./OutputTable";
import HorizontalCustomTable from "./HorizontalCustomTable";
import PreviousMatchUpCustom from "./PreviousMatchUpCustom";
import PreviousH2HCustom from "./PreviousH2HCustom";
import GameProbabilitiesCustom from "./GameProbabilitiesCustom";
import BettingTrendsCustom from "./BettingTrendsCustom";
import OddLineCustom from "./OddLineCustom";
import AnalyticsReportCustom from "./AnalyticsReportCustom";

// Passing each table data to custom table component

/***************BETTING SIGNALS TABLE*************/
export const BettingSignals = ({ tableWidth, data }) => {
  const upper_table = data?.full_upper_tables;
  const bettingSignalsHeaders = [
    "",
    "BUY 1/2 PT",
    "SPREAD",
    "MONEY LINE",
    "TOTAL",
  ];
  const bettingSignalsData = [
    [
      upper_table?.L10,
      upper_table?.["BETTING SIGNALS"]?.["BUY 1/2 PT_ROW1"],
      upper_table?.["BETTING SIGNALS"]?.["SPREAD_ROW1"],
      upper_table?.["BETTING SIGNALS"]?.["MONEY_LINE_ROW1"],
      [
        upper_table?.["BETTING SIGNALS"]?.["P9"],
        "   ",
        upper_table?.["BETTING SIGNALS"]?.["TOTAL_ROW1"],
      ],
    ],
    [
      upper_table?.L9,
      "-",
      upper_table?.["BETTING SIGNALS"]?.["SPREAD_ROW2"],
      upper_table?.["BETTING SIGNALS"]?.["MONEY_LINE_ROW2"],
      [
        upper_table?.["BETTING SIGNALS"]?.["P10"],
        "   ",
        upper_table?.["BETTING SIGNALS"]?.["TOTAL_ROW2"],
      ],
    ],
  ];

  return (
    <CustomTable
      title="Betting Signals"
      headers={bettingSignalsHeaders}
      data={bettingSignalsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      highlightText={true}
    />
  );
};

/***************MATCHUP ANALYSIS TABLE*************/
export const MatchupAnalysis = ({ tableWidth, data }) => {
  const match_up_Table = data?.full_upper_tables?.["MATCHUP ANALYSIS"];
  const matchupAnalysisHeaders = ["WIN PROBABILITY", "MATCHUP", "UPSET RISK"];
  const matchupAnalysisData = [
    [
      match_up_Table?.MATCHUP_ADVANTAGE_ROW1,
      match_up_Table?.MATCHUP_ROW1,
      match_up_Table?.UPSET_RISK_ROW1,
    ],
    [match_up_Table?.MATCHUP_ADVANTAGE_ROW2, match_up_Table?.MATCHUP_ROW2, "-"],
  ];

  return (
    <CustomTable
      title="Matchup Analysis"
      headers={matchupAnalysisHeaders}
      data={matchupAnalysisData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
    />
  );
};

/***************GAME PREDICTION TABLE*************/
export const GamePrediction = ({ tableWidth, data }) => {
  const game_predict_Table = data?.full_upper_tables?.["GAME PREDICTION"];
  const gamePredictionHeaders = ["SPREAD", "SCORES", "TOTAL"];
  const gamePredictionData = [
    [
      game_predict_Table?.SPREAD_ROW1,
      game_predict_Table?.SCORES_ROW1,
      game_predict_Table?.TOTAL_ROW1,
    ],
    [
      game_predict_Table?.SPREAD_ROW2,
      game_predict_Table?.SCORES_ROW2,
      game_predict_Table?.TOTAL_ROW2,
    ],
  ];

  return (
    <CustomTable
      title="Game Prediction"
      headers={gamePredictionHeaders}
      data={gamePredictionData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
    />
  );
};

/***************VEGAS LINES TABLE*************/
export const VegasLines = ({ tableWidth, data }) => {
  const vegas_lines_Table = data?.full_upper_tables?.["VEGAS LINES"];
  const VegasLineHeaders = ["SPREAD", "TOTAL", "MONEY LINE"];
  const VegasLineData = [
    [
      vegas_lines_Table?.SPREAD_ROW1,
      vegas_lines_Table?.TOTAL_ROW1,
      vegas_lines_Table?.MONEY_LINE_ROW1,
    ],
    [
      vegas_lines_Table?.SPREAD_ROW2,
      vegas_lines_Table?.TOTAL_ROW2 || "-",
      vegas_lines_Table?.MONEY_LINE_ROW2,
    ],
  ];

  return (
    <CustomTable
      title="Vegas Lines"
      headers={VegasLineHeaders}
      data={VegasLineData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      paddingCell="21.3px"
      fontSize="11px"
    />
  );
};

/***************RECORD STATS TABLE*************/
export const RecordStats = ({ tableWidth, data }) => {
  const record_table = data?.upper_middle_table;
  const RecordStatsHeaders = [
    "RECORD",
    record_table?.RECORD_HOME,
    record_table?.RECORD_AWAY,
  ];
  const RecordStatsData = [
    [
      "POWER RANK",
      record_table?.POWER_RANK_HOME,
      record_table?.POWER_RANK_AWAY,
    ],
    [
      "LAST 10 GAMES RANKING",
      record_table?.LAST_10_GAMES_RANKING_HOME,
      record_table?.LAST_10_GAMES_RANKING_AWAY,
    ],
    [
      "DIVISION RANK",
      record_table?.DIVISION_RANK_HOME,
      record_table?.DIVISION_RANK_AWAY,
    ],
    [
      "RANK VS TOP 10 TEAMS",
      record_table?.RANK_VS_TOP_10_TEAMS_HOME,
      record_table?.RANK_VS_TOP_10_TEAMS_AWAY,
    ],
    ["TOTAL", record_table?.TOTAL_HOME, record_table?.TOTAL_AWAY],
  ];

  return (
    <CustomTable
      title="Record stats"
      headers={RecordStatsHeaders}
      data={RecordStatsData}
      tableWidth={tableWidth}
      fontSize="12px"
      border="none"
    />
  );
};

/***************MINI MATCH TABLE*************/
export const MiniMatchTable = ({ tableWidth, data }) => {
  const extra_match_table = data?.extras;
  const MiniMatchData = [
    [
      data?.upper_middle_table?.RECORD_AWAY,
      extra_match_table?.AF12,
      extra_match_table?.AG12,
      extra_match_table?.AH12,
      extra_match_table?.AI12,
      extra_match_table?.AJ12,
    ],
    [
      data?.upper_middle_table?.RECORD_HOME,
      extra_match_table?.AF13,
      extra_match_table?.AG13,
      extra_match_table?.AH13,
      extra_match_table?.AI13,
      extra_match_table?.AJ13,
    ],
  ];
  return (
    <CustomTable
      title=""
      data={MiniMatchData}
      tableWidth={tableWidth}
      border="1px solid #ccc"
      highlightText={true}
      fontSize="11px"
    />
  );
};

/***************TEAM AVERAGES LINES TABLE*************/
export const TeamAverageLines = ({ tableWidth, data }) => {
  const teamAvg = data?.team_average_lines;
  const team_Avg_Extra = data?.extras;
  const RecordStatsHeaders = [
    "",
    "",
    "",
    "SPREAD",
    "SCORE",
    "TOTAL",
    "",
    "",
    "",
    "",
    "",
    "",
  ];
  const RecordStatsData = [
    [
      "",
      "",
      "POTENTIAL LOW SCORE",
      "",
      teamAvg?.Y15.toFixed(2),
      teamAvg?.AA15.toFixed(2),
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    [
      teamAvg?.Q16.toFixed(2),
      "AWAY",
      teamAvg?.AWAY,
      teamAvg?.X16,
      teamAvg?.Y16.toFixed(2),
      teamAvg?.AA16.toFixed(2),
      team_Avg_Extra?.AB16.toFixed(2),
      team_Avg_Extra?.AC16.toFixed(2),
      team_Avg_Extra?.AE16.toFixed(2),
      teamAvg?.AF16.toFixed(2),
      teamAvg?.AG16.toFixed(2),
      teamAvg?.AH16.toFixed(2),
    ],
    [
      teamAvg?.Q17.toFixed(2),
      "",
      "",
      "",
      teamAvg?.Y17.toFixed(2),
      teamAvg?.AA17.toFixed(2),
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    [
      "",
      "",
      "POTENTIAL HIGH SCORE",
      "",
      teamAvg?.Y18.toFixed(2),
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    [
      teamAvg?.Q19.toFixed(2),
      "HOME",
      teamAvg?.Home,
      teamAvg?.X19,
      teamAvg?.Y19.toFixed(2),
      "",
      "",
      team_Avg_Extra?.AC19.toFixed(2),
      team_Avg_Extra?.AE19.toFixed(2),
      teamAvg?.AF19.toFixed(2),
      teamAvg?.AG19.toFixed(2),
      teamAvg?.AH19.toFixed(2),
    ],
    ["", "", "", "", teamAvg?.Y20.toFixed(2), "", "", "", "", "", "", ""],
    [
      "",
      "",
      "",
      "",
      teamAvg?.Y22.toFixed(2),
      teamAvg?.AA22.toFixed(2),
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    ["", "", "", "", teamAvg?.Y23.toFixed(2), "", "", "", "", "", "", ""],
  ];
  return (
    <CustomTableAvgLines
      title="Team Average Lines"
      headers={RecordStatsHeaders}
      data={RecordStatsData}
      tableWidth={tableWidth}
      border="none"
      borderCell="none"
      paddingCell="8px 5px"
    />
  );
};
/*****************NEXT GAME TYPE TABLE******************/
export const NextGameType = ({ tableWidth, data }) => {
  const nextGameType_Table = data?.team_average_lines;

  const nextGameTypeData = [
    [
      "Next game: game type",
      nextGameType_Table?.W24,
      nextGameType_Table?.Y22,
      nextGameType_Table?.AA22,

    ],
    [
      nextGameType_Table?.["Next game: game type"],
     "",
     nextGameType_Table?.Y23,
     ""
    ],
    
  ];

  return (
    <CustomTable
      data={nextGameTypeData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      highlightText="#ffce19"
      fontSize="12px"
    />
  );
};
/*************** MATCH OUTPUT TABLE*************/
export const CustomOutputTable = ({ tableWidth, data }) => {
  const table_left = data?.main_middle_table_left;
  const table_right = data?.main_middle_table_right;
  const table_middle = data?.middle_table_probality;
  const RecordStatsHeaders = [
    "UNDERDOG",
    "FAVORITE",
    "",
    "FAVORITE",
    "UNDERDOG",
  ];
  const RecordStatsData = [
    [
      table_left?.MATCHUP_COMP_UNDERDOG,
      table_left?.MATCHUP_COMP_FAVORITE,
      "MATCHUP COMP",
      table_right?.MATCHUP_COMP_FAVORITE,
      table_right?.MATCHUP_COMP_UNDERDOG,
    ],
    [
      table_left?.MATCHUP_COMPARISON_ADJUSTMENT_UNDERDOG,
      table_left?.MATCHUP_COMPARISON_ADJUSTMENT_FAVORITE,
      "MATCHUP COMPARISON ADJUSTMENT",
      table_right?.MATCHUP_COMPARISON_ADJUSTMENT_FAVORITE,
      table_right?.MATCHUP_COMPARISON_ADJUSTMENT_UNDERDOG,
    ],
    [
      table_left?.RANK_SUMMARY_UNDERDOG,
      table_left?.RANK_SUMMARY_FAVORITE,
      "RANK SUMMARY",
      table_right?.RANK_SUMMARY_FAVORITE,
      table_right?.RANK_SUMMARY_UNDERDOG,
    ],
    [
      table_right?.PREVIOUS_10_GAMES_RANK_COMP_UNDERDOG,
      table_left?.PREVIOUS_10_GAMES_RANK_COMP_FAVORITE,
      "PREVIOUS 10 GAMES RANK COMP",
      table_right?.PREVIOUS_10_GAMES_RANK_COMP_FAVORITE,
      table_left?.PREVIOUS_10_GAMES_RANK_COMP_UNDERDOG,
    ],
    [
      table_left?.SHOOTING_UNDERDOG,
      table_left?.SHOOTING_FAVORITE,
      "SHOOTING",
      table_right?.SHOOTING_FAVORITE,
      table_right?.SHOOTING_UNDERDOG,
    ],
    [
      table_left?.REBOUNDING_UNDERDOG,
      table_left?.REBOUNDING_FAVORITE,
      "REBOUNDING",
      table_right?.REBOUNDING_FAVORITE,
      table_right?.REBOUNDING_UNDERDOG,
    ],
    [
      table_left?.FREE_THROWS_UNDERDOG,
      table_left?.FREE_THROWS_FAVORITE,
      "FREE THROWS",
      table_right?.FREE_THROWS_FAVORITE,
      table_right?.FREE_THROWS_UNDERDOG,
    ],
    [
      table_left?.TURNOVERS_UNDERDOG,
      table_left?.TURNOVERS_FAVORITE,
      "TURNOVERS",
      table_right?.TURNOVERS_FAVORITE,
      table_right?.TURNOVERS_UNDERDOG,
    ],
    ["", "", "TOTAL POINT ADJUSTMENT?", "", ""],
    [
      table_left?.["4TH_QTR_SCORING_MARGIN_UNDERDOG"],
      table_left?.["4TH_QTR_SCORING_MARGIN_FAVORITE"],
      "4TH QTR SCORING MARGIN",
      table_right?.["4TH_QTR_SCORING_MARGIN_FAVORITE"],
      table_right?.["4TH_QTR_SCORING_MARGIN_UNDERDOG"],
    ],
    [
      table_left?.POINTS_IN_THE_PAINT_UNDERDOG,
      table_left?.POINTS_IN_THE_PAINT_FAVORITE,
      "POINTS IN THE PAINT",
      table_right?.POINTS_IN_THE_PAINT_FAVORITE,
      table_right?.POINTS_IN_THE_PAINT_UNDERDOG,
    ],
    [
      table_left?.INJURIES_UNDERDOG,
      table_left?.INJURIES_FAVORITE,
      "INJURIES",
      table_right?.INJURIES_FAVORITE,
      table_right?.INJURIES_UNDERDOG,
    ],
    [
      table_left?.CLOSE_GAME_WIN_UNDERDOG,
      table_left?.CLOSE_GAME_WIN_FAVORITE,
      "CLOSE GAME WIN %",
      table_right?.CLOSE_GAME_WIN_FAVORITE,
      table_right?.CLOSE_GAME_WIN_UNDERDOG,
    ],
    [
      table_left?.LAST_10_UNDERDOG,
      table_left?.LAST_10_FAVORITE,
      "LAST 10",
      table_right?.LAST_10_FAVORITE,
      table_right?.LAST_10_UNDERDOG,
    ],
    [
      table_left?.SCORING_MARGIN_ADJUSTMENT_UNDERDOG,
      table_left?.SCORING_MARGIN_ADJUSTMENT_FAVORITE,
      "SCORING MARGIN ADJUSTMENT",
      table_right?.SCORING_MARGIN_ADJUSTMENT_FAVORITE,
      table_right?.SCORING_MARGIN_ADJUSTMENT_UNDERDOG,
    ],
    [
      table_left?.FREE_THROW_EFFICIENCY_ADJUSTMENT_UNDERDOG,
      table_left?.FREE_THROW_EFFICIENCY_ADJUSTMENT_FAVORITE,
      "FREE THROW EFFICIENCY ADJUSTMENT",
      table_right?.FREE_THROW_EFFICIENCY_ADJUSTMENT_FAVORITE,
      table_right?.FREE_THROW_EFFICIENCY_ADJUSTMENT_UNDERDOG,
    ],
    [
      table_left?.MATCH_UP_SIGNAL_UNDERDOG,
      table_left?.MATCH_UP_SIGNAL_FAVORITE,
      "MATCH UP SIGNAL",
      table_right?.MATCH_UP_SIGNAL_FAVORITE,
      table_right?.MATCH_UP_SIGNAL_UNDERDOG,
    ],
    [
      table_left?.SPREAD_ADJUSTMENT_UNDERDOG,
      table_left?.SPREAD_ADJUSTMENT_FAVORITE,
      "SPREAD ADJUSTMENT",
      table_right?.SPREAD_ADJUSTMENT_FAVORITE,
      table_right?.SPREAD_ADJUSTMENT_UNDERDOG,
    ],
    [
      table_left?.["PREVIOUS_HOME/TRAVEL_STRETCH_UNDERDOG"],
      table_left?.["PREVIOUS_HOME/TRAVEL_STRETCH_FAVORITE"],
      "PREVIOUS HOME/TRAVEL STRETCH",
      table_right?.["PREVIOUS_HOME/TRAVEL_STRETCH_FAVORITE"],
      table_right?.["PREVIOUS_HOME/TRAVEL_STRETCH_UNDERDOG"],
    ],
    [
      table_left?.MOMENTUM_UNDERDOG,
      table_left?.MOMENTUM_FAVORITE,
      "MOMENTUM",
      table_right?.MOMENTUM_FAVORITE,
      table_right?.MOMENTUM_UNDERDOG,
    ],
    [
      table_left?.MOTIVATION_STRETCH_UNDERDOG,
      table_left?.MOTIVATION_STRETCH_FAVORITE,
      "MOTIVATION",
      table_right?.MOTIVATION_STRETCH_FAVORITE,
      table_right?.MOTIVATION_STRETCH_UNDERDOG,
    ],
    [
      table_left?.REST_DAYS_UNDERDOG,
      table_left?.REST_DAYS_FAVORITE,
      "REST DAYS",

      table_right?.REST_DAYS_FAVORITE,
      table_right?.REST_DAYS_UNDERDOG,
    ],
    [
      table_left?.PACE_OF_PLAY_AVERAGE_UNDERDOG,
      table_left?.PACE_OF_PLAY_AVERAGE_FAVORITE,
      "PACE OF PLAY AVERAGE",
      table_right?.PACE_OF_PLAY_AVERAGE_FAVORITE,
      table_right?.PACE_OF_PLAY_AVERAGE_UNDERDOG,
    ],
    [
      table_left?.UPSET_PT_TOTAL_UNDERDOG,
      table_left?.UPSET_PT_TOTAL_FAVORITE,
      "UPSET PT TOTAL",
      table_right?.UPSET_PT_TOTAL_FAVORITE,
      table_right?.UPSET_PT_TOTAL_UNDERDOG,
    ],
    [
      table_left?.WIN_PT_TOTAL_UNDERDOG,
      table_left?.WIN_PT_TOTAL_FAVORITE,
      "WIN PT TOTAL",
      table_right?.WIN_PT_TOTAL_FAVORITE,
      table_right?.WIN_PT_TOTAL_UNDERDOG,
    ],
    [
      table_middle?.UPSET_PROBABILITY_UNDERDOG_LEFT,
      table_middle?.UPSET_PROBABILITY_FAVORITE_LEFT,
      "UPSET PROBABILITY",
      table_middle?.UPSET_PROBABILITY_FAVORITE_RIGHT,
      table_middle?.UPSET_PROBABILITY_UNDERDOG_RIGHT,
    ],
    [
      table_middle?.MATCHUP_ADV_UNDERDOG_LEFT,
      table_middle?.MATCHUP_ADV_FAVORITE_LEFT,
      "MATCHUP ADV",
      table_middle?.MATCHUP_ADV_FAVORITE_RIGHT,
      table_middle?.MATCHUP_ADV_UNDERDOG_RIGHT,
    ],
  ];
  return (
    <OutputTable
      title="TOTAL MATCH STATS"
      headers={RecordStatsHeaders}
      data={RecordStatsData}
      tableWidth={tableWidth}
      border="1px solid #ccc"
      paddingCell="4px 5px"
    />
  );
};
/*****************PREVIOUS H2H MATCHUPS******************/
export const PreviousH2HMatch = ({ tableWidth, data }) => {
  const PreviousH2HMatch_Table =
    data?.result_home?.lower_middle_table?.Lower_middle_table;
  const PreviousH2HMatchHeaders = [
    "",
    "",
    "",
    "",
    "",
    "",
    "Revenge",
    "Avg hth score",
  ];
  const PreviousH2HMatchData = [
    [
      data?.AWAY,
      PreviousH2HMatch_Table?.V87[1],
      PreviousH2HMatch_Table?.W87[1],
      PreviousH2HMatch_Table?.X87[1],
      PreviousH2HMatch_Table?.Y87[1],
      PreviousH2HMatch_Table?.Z87[1],
      PreviousH2HMatch_Table?.revenge_away,
      PreviousH2HMatch_Table?.Ave_hth_score_away,
    ],
    [
      null,
      PreviousH2HMatch_Table?.V87[0],
      PreviousH2HMatch_Table?.W87[0],
      PreviousH2HMatch_Table?.X87[0],
      PreviousH2HMatch_Table?.Y87[0],
      PreviousH2HMatch_Table?.Z87[0],
      null,
      null,
    ],
    [
      data?.HOME,
      PreviousH2HMatch_Table?.V89[1],
      PreviousH2HMatch_Table?.W89[1],
      PreviousH2HMatch_Table?.X89[1],
      PreviousH2HMatch_Table?.Y89[1],
      PreviousH2HMatch_Table?.Z89[1],
      PreviousH2HMatch_Table?.revenge_home,
      PreviousH2HMatch_Table?.Ave_hth_score_home,
    ],
    [
      null,
      PreviousH2HMatch_Table?.V89[0],
      PreviousH2HMatch_Table?.W89[0],
      PreviousH2HMatch_Table?.X89[0],
      PreviousH2HMatch_Table?.Y89[0],
      PreviousH2HMatch_Table?.Z89[0],
      null,
      null,
    ],
  ];

  return (
    <PreviousH2HCustom
      title="Previous H2H Matchups"
      headers={PreviousH2HMatchHeaders}
      data={PreviousH2HMatchData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="12px"
    />
  );
};
/******************PREVIOUS FIVE GAMES*************/
export const PreviousFiveGames = ({ tableWidth, data }) => {
  const PreviousFiveGames_Table = data?.lower_table;
  const PreviousFiveGamesHeaders = ["",
    "Home/Away",
    PreviousFiveGames_Table?.V94?.position,
    PreviousFiveGames_Table?.W94?.position,
    PreviousFiveGames_Table?.X94?.position,
    PreviousFiveGames_Table?.Y94?.position,
    PreviousFiveGames_Table?.Z94?.position,
    "Prev game start time",
    "Prev game ot",
    "spread of prev game",
    "Date of Prev Game",
    " Next game",
    "Last 5 game win count",
    "Ave scoring margin adjustment",
    "Home/away stretch",
    "Rest days",
    "Travel distance",
    "travel impact",
  ];
  const PreviousFiveGamesData = [
    [
    data?.AWAY,
    "Win/loss",
    PreviousFiveGames_Table?.V94?.result,
    PreviousFiveGames_Table?.W94?.result,
    PreviousFiveGames_Table?.X94?.result,
    PreviousFiveGames_Table?.Y94?.result,
    PreviousFiveGames_Table?.Z94?.result,
    PreviousFiveGames_Table?.["PREV GAME START TIME_AWAY"],
    PreviousFiveGames_Table?.["PREV GAME OT_AWAY"],
    PreviousFiveGames_Table?.["SPREAD OF PREV GAME_AWAY"],
    PreviousFiveGames_Table?.DATE_OF_PREV_GAME_AWAY,
    PreviousFiveGames_Table?.currentgame_date_AWAY,
    PreviousFiveGames_Table?.["AWAY_Last 5 game win count"],
    PreviousFiveGames_Table?.Ave_scoring_margin_adjustment_AWAY,
    PreviousFiveGames_Table?.["Home/away_stretch_away_row1"],
    PreviousFiveGames_Table?.Rest_days_AWAY,
    PreviousFiveGames_Table?.Travel_distance_away,
    PreviousFiveGames_Table?.travel_impact_away,
    ],
    [
      null,
      "team score",
      PreviousFiveGames_Table?.V94?.away_score,
      PreviousFiveGames_Table?.W94?.away_score,
      PreviousFiveGames_Table?.X94?.away_score,
      PreviousFiveGames_Table?.Y94?.away_score,
      PreviousFiveGames_Table?.Z94?.away_score,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      PreviousFiveGames_Table?.["Home/away_stretch_away_row2"],
      null,
      null,
      null
    ],
    [
      null,
      "opponent score",
      PreviousFiveGames_Table?.V94?.home_score,
      PreviousFiveGames_Table?.W94?.home_score,
      PreviousFiveGames_Table?.X94?.home_score,
      PreviousFiveGames_Table?.Y94?.home_score,
      PreviousFiveGames_Table?.Z94?.home_score,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    [
      null,
      "ATS",
      PreviousFiveGames_Table?.V94?.away_score,
      PreviousFiveGames_Table?.W94?.away_score,
      PreviousFiveGames_Table?.X94?.away_score,
      PreviousFiveGames_Table?.Y94?.away_score,
      PreviousFiveGames_Table?.Z94?.away_score,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    [
      "",
      "HOME/AWAY",
      PreviousFiveGames_Table?.V97?.position,
      PreviousFiveGames_Table?.W97?.position,
      PreviousFiveGames_Table?.X97?.position,
      PreviousFiveGames_Table?.Y97?.position,
      PreviousFiveGames_Table?.Z97?.position,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
    [
      data?.HOME,
      "Win/loss",
      PreviousFiveGames_Table?.V97?.result,
      PreviousFiveGames_Table?.W97?.result,
      PreviousFiveGames_Table?.X97?.result,
      PreviousFiveGames_Table?.Y97?.result,
      PreviousFiveGames_Table?.Z97?.result,
      PreviousFiveGames_Table?.["PREV GAME START TIME_HOME"],
    PreviousFiveGames_Table?.["PREV GAME OT_HOME"],
    PreviousFiveGames_Table?.["SPREAD OF PREV GAME_HOME"],
      PreviousFiveGames_Table?.DATE_OF_PREV_GAME_HOME,
      PreviousFiveGames_Table?.currentgame_date_HOME,
      PreviousFiveGames_Table?.["HOME_Last 5 game win count"],
      PreviousFiveGames_Table?.Ave_scoring_margin_adjustment_HOME,
      PreviousFiveGames_Table?.["Home/away_stretch_home_row1"],
      PreviousFiveGames_Table?.Rest_days_HOME,
      PreviousFiveGames_Table?.Travel_distance_home,
      PreviousFiveGames_Table?.travel_impact_home,
    ],
    [
      null,
      "team score",
      PreviousFiveGames_Table?.V97?.home_score,
      PreviousFiveGames_Table?.W97?.home_score,
      PreviousFiveGames_Table?.X97?.home_score,
      PreviousFiveGames_Table?.Y97?.home_score,
      PreviousFiveGames_Table?.Z97?.home_score,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      PreviousFiveGames_Table?.["Home/away_stretch_home_row2"],
      null,
      null,
      null
    ],
    [
      null,
      "opponent score",
      PreviousFiveGames_Table?.V97?.away_score,
      PreviousFiveGames_Table?.W97?.away_score,
      PreviousFiveGames_Table?.X97?.away_score,
      PreviousFiveGames_Table?.Y97?.away_score,
      PreviousFiveGames_Table?.Z97?.away_score,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    [
      null,
      "ATS",
      PreviousFiveGames_Table?.V97?.away_score,
      PreviousFiveGames_Table?.W97?.away_score,
      PreviousFiveGames_Table?.X97?.away_score,
      PreviousFiveGames_Table?.Y97?.away_score,
      PreviousFiveGames_Table?.Z97?.away_score,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    
  ];

  return (
    <PreviousMatchUpCustom
      title="Previous 5 games results"
      headers={PreviousFiveGamesHeaders}
      data={PreviousFiveGamesData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};
/***************HORIZONTAL SLIDE BARS*******************/

export const HorizontalSlideBar = ({ tableWidth, data }) => {
  const HorizontalTable = data?.horizontal_slide_bars;
  const HorizontalHeaders = ["", "HOME", "AWAY","COLOUR (HOME)","COLOUR (AWAY)"];
  const HorizontalTableData = [
    [
      "Matchup adv",
      HorizontalTable?.Matchup_adv_home,
      HorizontalTable?.Matchup_adv_away,
      HorizontalTable?.Matchup_adv_home_colour,
      HorizontalTable?.Matchup_adv_away_colour

    ],
    [
      "Momemtum",
      HorizontalTable?.Momentum_home,
      HorizontalTable?.Momentum_away,
      HorizontalTable?.Momentum_home_colour,
      HorizontalTable?.Momentum_away_colour
    ],
    ["High Upset Risk", HorizontalTable?.Upset_risk_home, "-", HorizontalTable?.Matchup_adv_home_colour,"-"],
    [
      "Rest/Travel Impact",
      HorizontalTable?.["Rest/travel_impact_home_row1"],
      HorizontalTable?.["Rest/travel_impact_away_row1"],
      "-",
      "-"
    ],
    [
      null,
      HorizontalTable?.["Rest/travel_impact_home_row2"],
      HorizontalTable?.["Rest/travel_impact_away_row2"],
      "-",
      "-"
    ],
    [
      null,
      HorizontalTable?.["Rest/travel_impact_home_row3"],
      HorizontalTable?.["Rest/travel_impact_away_row3"],
      HorizontalTable?.["Rest/travel_impact_home_row3_colour"],
      HorizontalTable?.["Rest/travel_impact_away_row3_colour"]
    ],
  ];

  return (
    <HorizontalCustomTable
      title="Horizontal slide bar"
      headers={HorizontalHeaders}
      data={HorizontalTableData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="12px"
    />
  );
};
/***************LIVE SCORE PROJECTION****************/
export const LiveProjection = ({ tableWidth, data }) => {
  const liveProjectionTable = data?.["Live score projection"];
  const liveProjectionHeaders = [
    "PPM",
    "LIVE SCORES",
    "CLOCK",
    "CURRENT PACE",
    "PROJECTED",
    "AVE",
    "LIVE PROJECTION",
    "TOTAL PROJECTION",
  ];
  const liveProjectionData = [
    [
      liveProjectionTable?.PPM_away,

      liveProjectionTable?.Live_scores_away,

      liveProjectionTable?.Clock_away,

      liveProjectionTable?.Current_pace_away,

      liveProjectionTable?.Projected_away,

      liveProjectionTable?.AVE_away,
      liveProjectionTable?.live_projection_away,
      liveProjectionTable?.["Total projection"],
    ],
    [
      liveProjectionTable?.PPM_home,
      liveProjectionTable?.Live_scores_home,
      liveProjectionTable?.Clock_home,
      liveProjectionTable?.Current_pace_home,
      liveProjectionTable?.Projected_home,
      liveProjectionTable?.AVE_home,
      liveProjectionTable?.live_projection_home,
      null,
    ],
  ];

  return (
    <CustomTable
      title="LIVE SCORE PROJECTION"
      headers={liveProjectionHeaders}
      data={liveProjectionData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};
/***************Game probabilities Table*************/
export const GameProbabilities = ({ tableWidth, data }) => {
  const GameProbabilitiesTable = data?.game_probabilities_table;
  const GameProbabilitiesHeaders = [ "",
  "",
  "",
  "",
      "",
      ,data?.HOME,"","",data?.AWAY, "","","","",""];
  const GameProbabilitiesData = [
    [
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.W122,
      GameProbabilitiesTable?.VEGAS_IMPLIED_PROBABILITY_HOME,
      "VEGAS IMPLIED PROBABILITY",
      GameProbabilitiesTable?.VEGAS_IMPLIED_PROBABILITY_AWAY,
      GameProbabilitiesTable?.AB122,
      "",
      "",
      "",
      ""
    ],
    [
      "",
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.ATS_Home,
      "ATS",
      GameProbabilitiesTable?.ATS_Away,
      "",
      "",
      "",
      "",
      ""
    ],
    [ "",
    "",
    "",
    "",
      GameProbabilitiesTable?.W124,
      GameProbabilitiesTable?.ATS_Home_ROW3,
      "ATS HOME",
      "ATS AWAY",
      GameProbabilitiesTable?.ATS_Away_ROW3,
      GameProbabilitiesTable?.AB124,
      "",
      "",
      "",
      "",
    ],
    [
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.W125,
      GameProbabilitiesTable?.["AVERAGE SCORING MARGIN_HOME"],
      "AVERAGE SCORING MARGIN",
      GameProbabilitiesTable?.["AVERAGE SCORING MARGIN_AWAY"],
      GameProbabilitiesTable?.AB125,
      "",
      "",
      "",
      ""

    ],
    [
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.W126,
      GameProbabilitiesTable?.["AVE @ HOME"],
      "AVE @ HOME",
      "AVE @ AWAY",
      GameProbabilitiesTable?.["AVE @ AWAY"],
      GameProbabilitiesTable?.AB126,
      "",
      "",
      "",
      "",
    ],
    [
      "",
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.["OVER % HOME"],
      "OVER %",
      GameProbabilitiesTable?.["OVER % AWAY"],
      "",
      "",
      "",
      "",
      ""
    ],
    [
      "",
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.["AVE @ HOME ROW128"],
      "AVE @ HOME",
      "AVE @ AWAY",
      GameProbabilitiesTable?.["AVE @ AWAY ROW128"],
      "",
      "",
      "",
      "",
      "",
      

    ],
    [ GameProbabilitiesTable?.S129,
      GameProbabilitiesTable?.T129,
      GameProbabilitiesTable?.U129,
      GameProbabilitiesTable?.V129,
      GameProbabilitiesTable?.W129,
      GameProbabilitiesTable?.["WIN PROBABILITY HOME"]*100,
      "WIN PROBABILITY",
      GameProbabilitiesTable?.["WIN PROBABILITY AWAY"]*100,
      GameProbabilitiesTable?.AB129,
      GameProbabilitiesTable?.AC129,
      GameProbabilitiesTable?.AD129,
      GameProbabilitiesTable?.AE129,
      ""

    ],
    [ "",
    "",
    "",
    "",
      "",
      GameProbabilitiesTable?.["SPREAD COVER PROBABILITY_HOME"],
      "SPREAD COVER PROBABILITY" ,
      GameProbabilitiesTable?.["SPREAD COVER PROBABILITY_AWAY"],
      "",
      "",
      "",
      "",
      ""
    ],
    [
      "",
      "",
      "",
      "",
      GameProbabilitiesTable?.W131,
      GameProbabilitiesTable?.["INDIVIDUAL POINTS COVER PROBABILITY_home"],
      "INDIVIDUAL POINTS COVER PROBABILITY ",
      GameProbabilitiesTable?.["INDIVIDUAL POINTS COVER PROBABILITY_away"],
      GameProbabilitiesTable?.AB131,
      "",
      "",
      "",
      ""
    ],

    [ "",
    "",
    "",
    "",
      "",
      "",
      "TOTAL POINTS COVER PROBABILITY" ,
      "",
      "",
      "",
      "",
      "",
      ""

    ],
    
      [
        "",
    "",
    "",
    "",
        "",
        "",
        GameProbabilitiesTable?.["TOTAL POINTS COVER PROBABILITY"] ,
        "",
        "",
        "",
    "",
    "",""
    ]

  ];

  return (
    <GameProbabilitiesCustom
      title="GAME PROBABILITIES"
      headers={GameProbabilitiesHeaders}
      data={GameProbabilitiesData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};
/***************Betting Trends Spread *************/
export const BettingTrendsSpread = ({ tableWidth, data }) => {
  const BettingTrends_Table = data?.betting_trends;
  const BettingTrendsHeaders = ["","HOME", " ", "AWAY"];
  const BettingTrendsData = [
    ["SPREAD",
      BettingTrends_Table?.["SPREAD BET % Home"],
      "BET %",
      BettingTrends_Table?.["SPREAD BET % Away"], 
  ],
  [
  BettingTrends_Table?.["SPREAD MONEY % Home"],
  "MONEY %",
  BettingTrends_Table?.["SPREAD MONEY % Away"], 
],
[
BettingTrends_Table?.["SPREAD DIFFERENCE % Home"],
"DIFFERENCE %",
BettingTrends_Table?.["SPREAD DIFFERENCE % Away"], 
],
[
BettingTrends_Table?.["SPREAD DISPLAY Home"],
"DISPLAY %",
BettingTrends_Table?.["SPREAD DISPLAY Away"], 
],
    
  ];

  return (
    <BettingTrendsCustom
      title="BETTING TRENDS"
      headers={BettingTrendsHeaders}
      data={BettingTrendsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
    />
  );
};

/***************Betting Trends Money Line*************/
export const BettingTrendsMoneyLine = ({ tableWidth, data }) => {
  const BettingTrends_Table = data?.betting_trends;
  const BettingTrendsHeaders = ["","HOME", " ", "AWAY"];
  const BettingTrendsData = [
    ["MONEY LINE",
      BettingTrends_Table?.["MONEY LINE BET % Home"],
      "BET %",
      BettingTrends_Table?.["MONEY LINE BET % Away"], 
  ],
  [
  BettingTrends_Table?.["MONEY LINE MONEY % Home"],
  "MONEY %",
  BettingTrends_Table?.["MONEY LINE MONEY % Away"], 
],
[
BettingTrends_Table?.["MONEY LINE DIFFERENCE % Home"],
"DIFFERENCE %",
BettingTrends_Table?.["MONEY LINE DIFFERENCE % Away"], 
],
[
BettingTrends_Table?.["MONEY LINE DISPLAY Home"],
"DISPLAY %",
BettingTrends_Table?.["MONEY LINE DISPLAY Away"], 
],
    
  ];

  return (
    <BettingTrendsCustom
      title="BETTING TRENDS"
      headers={BettingTrendsHeaders}
      data={BettingTrendsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
    />
  );
};

/**************Betting Trends Total*************/
export const BettingTrendsTotal = ({ tableWidth, data }) => {
  const BettingTrends_Table = data?.betting_trends;
  const BettingTrendsHeaders = ["","HOME", " ", "AWAY"];
  const BettingTrendsData = [
    ["TOTAL",
      BettingTrends_Table?.["TOTAL BET % Home"],
      "BET %",
      BettingTrends_Table?.["TOTAL BET % Away"], 
  ],
  [
  BettingTrends_Table?.["TOTAL MONEY % Home"],
  "MONEY %",
  BettingTrends_Table?.["TOTAL MONEY % Away"], 
],
[
BettingTrends_Table?.["TOTAL DIFFERENCE % Home"],
"DIFFERENCE %",
BettingTrends_Table?.["TOTAL DIFFERENCE % Away"], 
],
[
BettingTrends_Table?.["TOTAL DISPLAY Home"],
"DISPLAY %",
BettingTrends_Table?.["TOTAL DISPLAY Away"], 
],
    
  ];

  return (
    <BettingTrendsCustom
      title="BETTING TRENDS"
      headers={BettingTrendsHeaders}
      data={BettingTrendsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
    />
  );
};

/***************Odd line movement TABLE*************/
export const OddLineMovement = ({ tableWidth, data }) => {
  const OddLineMovement_Table = data?.betting_trends;
  const OddLineMovementHeaders = ["", "DATE", "LINE"];
  const OddLineMovementData = [
    [
      "OPEN",
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN DATE 1"],
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN LINE 1"],
    ],
    [
      null,
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN DATE 2"],
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN LINE 2"],
    ],
    [
      null,
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN DATE 3"],
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN LINE 3"],
    ],
    [
      null,
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN DATE 4"],
      OddLineMovement_Table?.["ODDS LINE MOVEMENT OPEN LINE 4"],
    ],
    [
      "CLOSE",
      OddLineMovement_Table?.["ODDS LINE MOVEMENT CLOSE DATE 5"],
      OddLineMovement_Table?.["ODDS LINE MOVEMENT CLOSE LINE 5"],
    ],
    [
      null,
     "Difference",
      OddLineMovement_Table?.["ODDS LINE MOVEMENT DIFFERENCE"],
    ],
  ];

  return (
    <OddLineCustom
      title="Odds line movement"
      headers={OddLineMovementHeaders}
      data={OddLineMovementData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
    />
  );
};

/*==========================PROPS============================*/


/********3PT********/

export const PTPropsTable = ({ tableWidth, propsData }) => {
  const PTProps_Table = propsData?.pg_props_table[0];
  const PTPropsHeaders = ["TYPE", "WT", "3PT M","STAT RANK","PTS"];
  const PtPropsData = [
    [
      "P",
      PTProps_Table?.["3PTM_AVE_3PM_PG_STAT_PTS"],
      "AVE 3PM PG",
      PTProps_Table?.["3PTM_AVE_3PM_PG_STAT_RANK"],
      PTProps_Table?.["3PTM_AVE_3PM_PG_PTS"],
    ],
    [
      "P",
      "0.50",
      "3PT%",
      PTProps_Table?.["3PTM_3PT %_STAT_RANK"],
      PTProps_Table?.["3PTM_3PT %_PTS"],
    ],
    [
      "P",
      PTProps_Table?.["3PTM_3PT_RATE_STAT_PTS"],
      "3PT RATE",
      PTProps_Table?.["3PTM_3PT_RATE_STAT_RANK"],
      ""
    ],
    [
      "P",
      "0.50",
      "USG%",
      PTProps_Table?.["3PTM_USG%_STAT_RANK"],
      PTProps_Table?.["3PTM_USG%_PTS"],
    ],

    [
      "TO",
      "0.50",
      "DEF EFF",
      PTProps_Table?.["3PTM_DEF_EFF_STAT_RANK"],
      PTProps_Table?.["3PTM_DEF_EFF_PTS"],
    ],
    [
      "TO",
      PTProps_Table?.["3PTM_3PT_RATE_STAT_PTS"],
      "3PT RATE",
      PTProps_Table?.["3PTM_3PT_RATE_STAT_RANK"],
      ""
    ],
    [
      "API",
      "0.50",
      "OPP against position",
      PTProps_Table?.["3PTM_OPP against position_STAT_RANK"],
      PTProps_Table?.["3PTM_OPP against position_PTS"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 5",
      "",
      PTProps_Table?.["3PTM_Ov/un current line-last 5"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 10",
      "",
      PTProps_Table?.["3PTM_Ov/un current line-last 10"],
    ],
    [
      "API",
      "%",
      "Ov/un AVE",
      "",
      PTProps_Table?.["3PTM_Ov/un AVE"],
    ],
    [
      "API",
      "",
      "VEGAS LINE",
      "",
      PTProps_Table?.["3PTM_VEGAS_LINE"],
    ],
    [
      "F",
      "",
      "PROJECTION",
      "",
      PTProps_Table?.["3PTM_VEGAS_PROJECTION"],
    ],
    [
      "F",
      "",
      "PTS ADDED VS VEGAS LINE",
      "",
      PTProps_Table?.["3PTM_PTS ADDED VS VEGAS LINE"],
    ],
    [
      "F",
      "OV",
      "FINAL CALCULATION",
      "",
      PTProps_Table?.["3PTM_FINAL CALCULATION"],
    ],

  ];

  return (
    <CustomTable
      title="3PT"
      headers={PTPropsHeaders}
      data={PtPropsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};

/********PPG********/

export const PPGTable = ({ tableWidth, propsData }) => {
  const Props_Table = propsData?.pg_props_table[0];
  const PPGHeaders = ["TYPE", "WT", "PPG","STAT RANK","PTS"];
  const PPGData = [
    [
      "P",
    "",
      "AVE PPG",
    "",
    Props_Table?.["PPG_AVE_PPG_PTS"],
    ],
    [
      "P",
      "0.50",
      "Off win shares",
    Props_Table?.["PPG_Off_win_shares_STAT_RANK"],
    Props_Table?.["PPG_Off_win_shares_PTS"],
    ],
    [
      "P",
      "0.50",
      "USG%",
    Props_Table?.["PPG_USG%_STAT_RANK"],
    Props_Table?.["PPG_USG%_PTS"],
    ],

    [
      "TO",
      "1",
      "DEF EFF",
    Props_Table?.["PPG_DEF_EFF_STAT_RANK"],
    Props_Table?.["PPG_DEF_EFF_PTS"],
    ],
    [
      "TO",
      "1",
      "FLOOR%",
    Props_Table?.["3PTM_DEF_EFF_STAT_RANK"],
    Props_Table?.["3PTM_DEF_EFF_PTS"],
    ],
    [
      "TO",
      "1",
      "TS%",
    Props_Table?.["PPG_FLOOR%_STAT_RANK"],
    Props_Table?.["PPG_FLOOR%_PTS"],
    ],
    [
      "TO",
      "1",
      "FTA PER FGA",
    Props_Table?.["PPG_FTA_PER_FGA_STAT_RANK"],
    Props_Table?.["PPG_FTA_PER_FGA_PTS"],
    ],
    [
      "TO",
      "1",
      "PPG",
    Props_Table?.["PPG_PPG_STAT_RANK"],
    Props_Table?.["PPG_PPG_PTS"],
    ],

    [
      "API",
      "1",
      "OPP against position",
    Props_Table?.["PPG_OPP against position_STAT_RANK"],
    Props_Table?.["PPG_OPP against position_PTS"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 5",
      "",
    Props_Table?.["PPG_Ov/un current line-last 5"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 10",
      "",
    Props_Table?.["PPG_Ov/un current line-last 10"],
    ],
    [
      "API",
      "%",
      "Ov/un AVE",
      "",
    Props_Table?.["PPG_Ov/un AVE"],
    ],
    [
      "API",
      "",
      "VEGAS LINE",
      "",
    Props_Table?.["PPG_VEGAS_LINE"],
    ],
    [
      "F",
      "",
      "PROJECTION",
      "",
    Props_Table?.["PPG_VEGAS_PROJECTION"],
    ],
    [
      "F",
      "",
      "PTS ADDED VS VEGAS LINE",
      "",
    Props_Table?.["PPG_PTS ADDED VS VEGAS LINE"],
    ],
    [
      "F",
      "OV",
      "FINAL CALCULATION",
      "",
    Props_Table?.["PPG_FINAL CALCULATION"],
    ],

  ];

  return (
    <CustomTable
      title="PPG"
      headers={PPGHeaders}
      data={PPGData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};

/********Assists********/

export const AssistTable = ({ tableWidth, propsData }) => {
  const Props_Table = propsData?.pg_props_table[0];
  const AssistHeaders = ["TYPE", "WT", "ASSISTS","STAT RANK","PTS"];
  const AssistData = [
    [
      "P",
    "",
      "AVE ASSIST PG",
    "",
    Props_Table?.["ASSISTS_AVE_ASSIST_PG_PTS"],
    ],
    [
      "P",
      "1",
      "ASST %",
    Props_Table?.["ASSISTS_Asst_%_STAT_RANK"],
    Props_Table?.["ASSISTS_Asst_%_PTS"],
    ],
    [
      "P",
      "1",
      "USG%",
    Props_Table?.["ASSISTS_USG%_STAT_RANK"],
    Props_Table?.["ASSISTS_USG%_PTS"],
    ],
    [
      "P",
      "0.50",
      "OBPM",
    Props_Table?.["PPG_USG%_STAT_RANK"],
    Props_Table?.["PPG_USG%_PTS"],
    ],

    [
      "TO",
      "0.50",
      "DEF EFF",
    Props_Table?.["ASSISTS_DEF_EFF_STAT_RANK"],
    Props_Table?.["ASSISTS_DEF_EFF_PTS"],
    ],
    [
      "TO",
      "1",
      "ASST PER FGM",
    Props_Table?.["ASSISTS_ASST_PER_FGM_STAT_RANK"],
    Props_Table?.["ASSISTS_ASST_PER_FGM_PTS"],
    ],

    [
      "API",
      "0.50",
      "OPP against position",
    Props_Table?.["ASSISTS_OPP against position_STAT_RANK"],
    Props_Table?.["ASSISTS_OPP against position_PTS"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 5",
      "",
    Props_Table?.["ASSISTS_Ov/un current line-last 5"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 10",
      "",
    Props_Table?.["ASSISTS_Ov/un current line-last 10"],
    ],
    [
      "API",
      "%",
      "Ov/un AVE",
      "",
    Props_Table?.["ASSISTS_Ov/un AVE"],
    ],
    [
      "API",
      "",
      "VEGAS LINE",
      "",
    Props_Table?.["ASSISTS_VEGAS_LINE"],
    ],
    [
      "F",
      "",
      "PROJECTION",
      "",
    Props_Table?.["ASSISTS_VEGAS_PROJECTION"],
    ],
    [
      "F",
      "",
      "PTS ADDED VS VEGAS LINE",
      "",
    Props_Table?.["ASSISTS_PTS ADDED VS VEGAS LINE"],
    ],
    [
      "F",
      "UN",
      "FINAL CALCULATION",
      "",
    Props_Table?.["ASSISTS_FINAL CALCULATION"],
    ],

  ];

  return (
    <CustomTable
      title="Assists"
      headers={AssistHeaders}
      data={AssistData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};


/********Rebounds********/

export const ReboundsTable = ({ tableWidth, propsData }) => {
  const Props_Table = propsData?.pg_props_table[0];
  const ReboundsHeaders = ["TYPE", "WT", "REBOUNDS","STAT RANK","PTS"];
  const ReboundsData = [
    [
      "P",
    "",
      "TOT Reb PG",
    "",
    Props_Table?.["REBOUNDS_TOT_Reb_PG_PTS"],
    ],
    [
      "P",
      "0.50",
      "TReb %",
    Props_Table?.["REBOUNDS_TReb %_STAT_RANK"],
    Props_Table?.["REBOUNDS_TReb %_PTS"],
    ],
    [
      "P",
      "0.50",
      "USG%",
    Props_Table?.["REBOUNDS_USG %_STAT_RANK"],
    Props_Table?.["REBOUNDS_USG %_PTS"],
    ],
    [
      "TO",
      "0.50",
      "DREB%",
    Props_Table?.["REBOUNDS_DREB %_STAT_RANK"],
    Props_Table?.["REBOUNDS_DREB %_PTS"],
    ],

    [
      "TO",
      "0.50",
      "OREB%",
    Props_Table?.["REBOUNDS_OREB %_STAT_RANK"],
    Props_Table?.["REBOUNDS_OREB %_PTS"],
    ],

    [
      "API",
      "1",
      "OPP against position",
    Props_Table?.["REBOUNDS_OPP against position_STAT_RANK"],
    Props_Table?.["REBOUNDS_OPP against position_PTS"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 5",
      "",
    Props_Table?.["REBOUNDS_Ov/un current line-last 5"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 10",
      "",
    Props_Table?.["REBOUNDS_Ov/un current line-last 10"],
    ],
    [
      "API",
      "%",
      "Ov/un AVE",
      "",
    Props_Table?.["REBOUNDS_Ov/un AVE"],
    ],
    [
      "API",
      "",
      "VEGAS LINE",
      "",
    Props_Table?.["REBOUNDS_VEGAS_LINE"],
    ],
    [
      "F",
      "",
      "PROJECTION",
      "",
    Props_Table?.["REBOUNDS_VEGAS_PROJECTION"],
    ],
    [
      "F",
      "",
      "PTS ADDED VS VEGAS LINE",
      "",
    Props_Table?.["REBOUNDS_PTS ADDED VS VEGAS LINE"],
    ],
    [
      "F",
      "OV",
      "FINAL CALCULATION",
      "",
    Props_Table?.["REBOUNDS_FINAL CALCULATION"],
    ],

  ];

  return (
    <CustomTable
      title="Rebounds"
      headers={ReboundsHeaders}
      data={ReboundsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};

/********Steals********/

export const StealsTable = ({ tableWidth, propsData }) => {
  const Props_Table = propsData?.pg_props_table[0];
  const StealsHeaders = ["TYPE", "WT", "STEALS","STAT RANK","PTS"];
  const StealsData = [
    [
      "P",
    "",
      "Steals PG",
    "",
    Props_Table?.["STEALS_Steals_PG_PTS"],
    ],
    [
      "P",
      "0.50",
      "Steal %",
    Props_Table?.["STEALS_Steal %_STAT_RANK"],
    Props_Table?.["STEALS_Steal %_PTS"],
    ],
    [
      "TO",
      "0.50",
      "TO PG",
    Props_Table?.["STEALS_TO PG_STAT_RANK"],
    Props_Table?.["STEALS_TO PG_PTS"],
    ],
    

    [
      "API",
      "0.5",
      "OPP against position",
    Props_Table?.["STEALS_OPP against position_STAT_RANK"],
    Props_Table?.["STEALS_OPP against position_PTS"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 5",
      "",
    Props_Table?.["STEALS_Ov/un current line-last 5"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 10",
      "",
    Props_Table?.["STEALS_Ov/un current line-last 10"],
    ],
    [
      "API",
      "%",
      "Ov/un AVE",
      "",
    Props_Table?.["STEALS_Ov/un AVE"],
    ],
    [
      "API",
      "",
      "VEGAS LINE",
      "",
    Props_Table?.["STEALS_VEGAS_LINE"],
    ],
    [
      "F",
      "",
      "PROJECTION",
      "",
    Props_Table?.["STEALS_VEGAS_PROJECTION"],
    ],
    [
      "F",
      "",
      "PTS ADDED VS VEGAS LINE",
      "",
    Props_Table?.["STEALS_PTS ADDED VS VEGAS LINE"],
    ],
    [
      "F",
      "OV",
      "FINAL CALCULATION",
      "",
    Props_Table?.["STEALS_FINAL CALCULATION"],
    ],

  ];

  return (
    <CustomTable
      title="Steals"
      headers={StealsHeaders}
      data={StealsData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};


/********Blocks********/

export const BlocksTable = ({ tableWidth, propsData }) => {
  const Props_Table = propsData?.pg_props_table[0];
  const BlocksHeaders = ["TYPE", "WT", "BLOCKS","STAT RANK","PTS"];
  const BlocksData = [
    [
      "P",
    "",
      "Blocks PG",
    "",
    Props_Table?.["BLOCKS_Blocks_PG_PTS"],
    ],
    [
      "P",
      "0.50",
      "Block %",
    Props_Table?.["BLOCKS_Block %_STAT_RANK"],
    Props_Table?.["BLOCKS_Block %_PTS"],
    ],
    [
      "TO",
      "0.50",
      "BLOCKS PG",
    Props_Table?.["BLOCKS_Blocks PG_STAT_RANK"],
    Props_Table?.["BLOCKS_Blocks PG_PTS"],
    ],
    

    [
      "API",
      "1",
      "OPP against position",
    Props_Table?.["BLOCKS_OPP against position_STAT_RANK"],
    Props_Table?.["BLOCKS_OPP against position_PTS"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 5",
      "",
    Props_Table?.["BLOCKS_Ov/un current line-last 5"],
    ],
    [
      "API",
      "%",
      "Ov/un current line-last 10",
      "",
    Props_Table?.["BLOCKS_Ov/un current line-last 10"],
    ],
    [
      "API",
      "%",
      "Ov/un AVE",
      "",
    Props_Table?.["BLOCKS_Ov/un AVE"],
    ],
    [
      "API",
      "",
      "VEGAS LINE",
      "",
    Props_Table?.["BLOCKS_VEGAS_LINE"],
    ],
    [
      "F",
      "",
      "PROJECTION",
      "",
    Props_Table?.["BLOCKS_VEGAS_PROJECTION"],
    ],
    [
      "F",
      "",
      "PTS ADDED VS VEGAS LINE",
      "",
    Props_Table?.["BLOCKS_PTS ADDED VS VEGAS LINE"],
    ],
    [
      "F",
      "UN",
      "FINAL CALCULATION",
      "",
    Props_Table?.["BLOCKS_FINAL CALCULATION"],
    ],

  ];

  return (
    <CustomTable
      title="Blocks"
      headers={BlocksHeaders}
      data={BlocksData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      fontSize="11px"
    />
  );
};

/************** ANALYTICS TABLE*************/
export const AnalyticsReportTable = ({ tableWidth, data, match1, match2 }) => {
  const AnalyticsReport_Table = data;
  const AnalyticsReportHeaders = [match1, "", "","",match2,"Team to play","Header","Display","Button"];
  const AnalyticsReportData = [
    [
      AnalyticsReport_Table?.[`${match1}_Free throw efficiency`],
      "Free throw efficiency ",
      "VS",
      "Fouls (per possession)",
      AnalyticsReport_Table?.[`${match2}_Fouls_per_possession`],
      AnalyticsReport_Table?.["Team_to_play_row3"][0],
      AnalyticsReport_Table?.["Team_to_play_row3"][1],
      AnalyticsReport_Table?.["Team_to_play_row3"][2],
      AnalyticsReport_Table?.["Team_to_play_row3"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Fast break offense`],
      "Fast break offense ",
      "VS",
      "Fast break defense",
      AnalyticsReport_Table?.[`${match2}_Fast_break_defense`],
      AnalyticsReport_Table?.["Team_to_play_row4"][0],
      AnalyticsReport_Table?.["Team_to_play_row4"][1],
      AnalyticsReport_Table?.["Team_to_play_row4"][2],
      AnalyticsReport_Table?.["Team_to_play_row4"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Points in the paint`],
      "Points in the paint ",
      "VS",
      "Defense in the paint",
      AnalyticsReport_Table?.[`${match2}_Defense_in_the_paint`],
      AnalyticsReport_Table?.["Team_to_play_row5"][0],
      AnalyticsReport_Table?.["Team_to_play_row5"][1],
      AnalyticsReport_Table?.["Team_to_play_row5"][2],
      AnalyticsReport_Table?.["Team_to_play_row5"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Offensive efficiency`],
      "Offensive efficiency ",
      "VS",
      "Defensive efficiency ",
      AnalyticsReport_Table?.[`${match2}_Defensive_efficiency`],
      AnalyticsReport_Table?.["Team_to_play_row6"][0],
      AnalyticsReport_Table?.["Team_to_play_row6"][1],
      AnalyticsReport_Table?.["Team_to_play_row6"][2],
      AnalyticsReport_Table?.["Team_to_play_row6"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Scoring margin @ home`],
      "Scoring margin @ home",
      "VS",
      "Scoring margin on the road",
      AnalyticsReport_Table?.[`${match2}_Scoring_margin_on_the_road`],
      AnalyticsReport_Table?.["Team_to_play_row7"][0],
      AnalyticsReport_Table?.["Team_to_play_row7"][1],
      AnalyticsReport_Table?.["Team_to_play_row7"][2],
      AnalyticsReport_Table?.["Team_to_play_row7"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Shooting (TS%)`],
      "Shooting (TS%)",
      "VS",
      "Shooting (TS%) allowed",
      AnalyticsReport_Table?.[`${match2}_Shooting_TS%_allowed`],
      AnalyticsReport_Table?.["Team_to_play_row8"][0],
      AnalyticsReport_Table?.["Team_to_play_row8"][1],
      AnalyticsReport_Table?.["Team_to_play_row8"][2],
      AnalyticsReport_Table?.["Team_to_play_row8"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_When at home`],
      "When at home",
      "VS",
      "As a road team",
      AnalyticsReport_Table?.[`${match2}_As_a_road_team`],
      AnalyticsReport_Table?.["Team_to_play_row9"][0],
      AnalyticsReport_Table?.["Team_to_play_row9"][1],
      AnalyticsReport_Table?.["Team_to_play_row9"][2],
      AnalyticsReport_Table?.["Team_to_play_row9"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Strength of schedule`],
      "Strength of schedule",
      "VS",
      "Strength of schedule",
      AnalyticsReport_Table?.[`${match2}_Strength_of_schedule`],
      AnalyticsReport_Table?.["Team_to_play_row10"][0],
      AnalyticsReport_Table?.["Team_to_play_row10"][1],
      AnalyticsReport_Table?.["Team_to_play_row10"][2],
      AnalyticsReport_Table?.["Team_to_play_row10"][3]
    ],
    [
      AnalyticsReport_Table?.[`${match1}_3 point efficiency`],
      "3 point efficiency ",
      "VS",
      "3 point efficiency allowed",
      AnalyticsReport_Table?.[`${match2}_3_point_efficiency_allowed`],
      AnalyticsReport_Table?.["Team_to_play_row11"][0],
      AnalyticsReport_Table?.["Team_to_play_row11"][1],
      AnalyticsReport_Table?.["Team_to_play_row11"][2],
      AnalyticsReport_Table?.["Team_to_play_row11"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Assists (per FGM)`],
      "Assists (per FGM)",
      "VS",
      "Allowing assists (per FGM)",
      AnalyticsReport_Table?.[`${match2}_Allowing_assists_per_FGM`],
      AnalyticsReport_Table?.["Team_to_play_row12"][0],
      AnalyticsReport_Table?.["Team_to_play_row12"][1],
      AnalyticsReport_Table?.["Team_to_play_row12"][2],
      AnalyticsReport_Table?.["Team_to_play_row12"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Rebound %`],
      "Rebound %",
      "VS",
      "Rebound % allowed",
      AnalyticsReport_Table?.[`${match2}_Rebound_percentage_allowed`],
      AnalyticsReport_Table?.["Team_to_play_row13"][0],
      AnalyticsReport_Table?.["Team_to_play_row13"][1],
      AnalyticsReport_Table?.["Team_to_play_row13"][2],
      AnalyticsReport_Table?.["Team_to_play_row13"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Turnovers`],
      "Turnovers",
      "VS",
      "Forcing Turnovers",
      AnalyticsReport_Table?.[`${match2}_Forcing_turnovers`],
      AnalyticsReport_Table?.["Team_to_play_row14"][0],
      AnalyticsReport_Table?.["Team_to_play_row14"][1],
      AnalyticsReport_Table?.["Team_to_play_row14"][2],
      AnalyticsReport_Table?.["Team_to_play_row14"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Offensive efficiency`],
      "Offensive efficiency",
      "VS",
      "Offensive efficiency",
      AnalyticsReport_Table?.[`${match2}_Offensive_efficiency`],
      AnalyticsReport_Table?.["Team_to_play_row15"][0],
      AnalyticsReport_Table?.["Team_to_play_row15"][1],
      AnalyticsReport_Table?.["Team_to_play_row15"][2],
      AnalyticsReport_Table?.["Team_to_play_row15"][3]
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Defensive efficiency`],
      "Defensive efficiency",
      "VS",
      "Defensive efficiency",
      AnalyticsReport_Table?.[`${match2}_Defensive_efficiency`],
      AnalyticsReport_Table?.["Team_to_play_row16"][0],
      AnalyticsReport_Table?.["Team_to_play_row16"][1],
      AnalyticsReport_Table?.["Team_to_play_row16"][2],
      AnalyticsReport_Table?.["Team_to_play_row16"][3]
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Fouls (per possession)`],
      "Fouls (per possession)",
      "VS",
      "Free throw efficiency ",
      AnalyticsReport_Table?.[`${match2}_Free_throw_efficiency`],
      AnalyticsReport_Table?.["Team_to_play_row17"][0],
      AnalyticsReport_Table?.["Team_to_play_row17"][1],
      AnalyticsReport_Table?.["Team_to_play_row17"][2],
      AnalyticsReport_Table?.["Team_to_play_row17"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Fast break defense`],
      "Fast break defense ",
      "VS",
      "Fast break offense",
      AnalyticsReport_Table?.[`${match2}_Fast_break_offense`],
      AnalyticsReport_Table?.["Team_to_play_row18"][0],
      AnalyticsReport_Table?.["Team_to_play_row18"][1],
      AnalyticsReport_Table?.["Team_to_play_row18"][2],
      AnalyticsReport_Table?.["Team_to_play_row18"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Defense in the paint`],
      "Defense in the paint ",
      "VS",
      "Points in the paint",
      AnalyticsReport_Table?.[`${match2}_Points_in_the_paint`],
      AnalyticsReport_Table?.["Team_to_play_row19"][0],
      AnalyticsReport_Table?.["Team_to_play_row19"][1],
      AnalyticsReport_Table?.["Team_to_play_row19"][2],
      AnalyticsReport_Table?.["Team_to_play_row19"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Shooting (TS%) allowed`],
      "Shooting (TS%) allowed",
      "VS",
      "Shooting (TS%)",
      AnalyticsReport_Table?.[`${match2}_Shooting_TS%`],
      AnalyticsReport_Table?.["Team_to_play_row21"][0],
      AnalyticsReport_Table?.["Team_to_play_row21"][1],
      AnalyticsReport_Table?.["Team_to_play_row21"][2],
      AnalyticsReport_Table?.["Team_to_play_row21"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_3 point efficiency allowed`],
      "3 point efficiency allowed",
      "VS",
      "3 point efficiency",
      AnalyticsReport_Table?.[`${match2}_3_point_efficiency`],
      AnalyticsReport_Table?.["Team_to_play_row22"][0],
      AnalyticsReport_Table?.["Team_to_play_row22"][1],
      AnalyticsReport_Table?.["Team_to_play_row22"][2],
      AnalyticsReport_Table?.["Team_to_play_row22"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Allowing assists (per FGM)`],
      "Allowing assists (per FGM)",
      "VS",
      "Assists (per FGM)",
      AnalyticsReport_Table?.[`${match2}_Assists_per_FGM`],
      AnalyticsReport_Table?.["Team_to_play_row23"][0],
      AnalyticsReport_Table?.["Team_to_play_row23"][1],
      AnalyticsReport_Table?.["Team_to_play_row23"][2],
      AnalyticsReport_Table?.["Team_to_play_row23"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Rebound % allowed`],
      "Rebound % allowed",
      "VS",
      "Rebound %",
      AnalyticsReport_Table?.[`${match2}_Rebound_percentage`],
      AnalyticsReport_Table?.["Team_to_play_row24"][0],
      AnalyticsReport_Table?.["Team_to_play_row24"][1],
      AnalyticsReport_Table?.["Team_to_play_row24"][2],
      AnalyticsReport_Table?.["Team_to_play_row24"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Forcing turnovers`],
      "Forcing Turnovers",
      "VS",
      "Turnovers",
      AnalyticsReport_Table?.[`${match2}_Turnovers`],
      AnalyticsReport_Table?.["Team_to_play_row25"][0],
      AnalyticsReport_Table?.["Team_to_play_row25"][1],
      AnalyticsReport_Table?.["Team_to_play_row25"][2],
      AnalyticsReport_Table?.["Team_to_play_row25"][3]
  
    ],
    [
      AnalyticsReport_Table?.[`${match1}_Pace of play`],
      "Pace of Play",
      "VS",
      "Pace of Play",
      AnalyticsReport_Table?.[`${match2}_Pace_of_play`],
      AnalyticsReport_Table?.["Team_to_play_row26"][0],
      AnalyticsReport_Table?.["Team_to_play_row26"][1],
      AnalyticsReport_Table?.["Team_to_play_row26"][2],
      AnalyticsReport_Table?.["Team_to_play_row26"][3]
  
    ],
  ];

  return (
    <AnalyticsReportCustom
      title="ANALYTICS REPORT"
      headers={AnalyticsReportHeaders}
      data={AnalyticsReportData}
      tableWidth={tableWidth}
      border="1px solid #cccccc"
      paddingCell="21.3px"
      fontSize="11px"
    />
  );
};

