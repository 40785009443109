import React from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Typography } from "@mui/material";
import "../App.css";

function InjuryReport({ tableWidth, data }) {
    // Guard clause to handle cases where `data` or `data.Injury_report` might be undefined or null
    const injury_report = data?.Injury_report || {};

    // Function to dynamically get player keys
    const getPlayerKeys = (teamType) => {
        return Object.keys(injury_report).filter(key => key.startsWith(teamType));
    };

    // Function to render player rows
    const renderPlayerRow = (playerKey) => {
        const playerData = injury_report[playerKey] || {};

        // Helper function to display value or hyphen
        const displayValue = (value) => (value !== undefined && value !== null && value !== "") ? value : "-";

        return (
            <>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                    <center>{displayValue(playerData["TEAM"])}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 80 }}>
                    <center>{displayValue(playerData["PLAYER"])}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 60 }}>
                    <center>{displayValue(playerData["DISPLAY"])}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                    <center>{displayValue(playerData["VORP"])}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                    <center>{displayValue(playerData["PERCENTILE"]?.toFixed(2))}</center>
                </TableCell>
                <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                    <center>{displayValue(playerData["IMPACT"])}</center>
                </TableCell>
            </>
        );
    };

    return (
        <TableContainer
            component={Paper}
            style={{
                margin: "0 20px",
                backgroundColor: "transparent",
                color: "white",
                border: "1px solid #cccccc",
                width: tableWidth,
            }}
        >
            <Typography
                variant="subtitle1"
                sx={{ textTransform: "uppercase", marginTop: "10px" }}
            >
                <center>INJURY REPORT</center>
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        {(injury_report?.home_player1 || injury_report?.home_player2 || injury_report?.home_player3 || injury_report?.home_player4) && (<><TableCell></TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>TEAM</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>PLAYER</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>DISPLAY</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>VORP</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>PERCENTILE</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>IMPACT</TableCell></>)}
                       {(injury_report?.away_player1 || injury_report?.away_player2 || injury_report?.away_player3 || injury_report?.away_player4) && (<><TableCell></TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>TEAM</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>PLAYER</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>DISPLAY</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>VORP</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>PERCENTILE</TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px" }}>IMPACT</TableCell></>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                    {(injury_report?.home_player1 || injury_report?.home_player2 || injury_report?.home_player3 || injury_report?.home_player4) &&   <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px", width: 40, borderRight: "1px solid #ccc" }} rowSpan={5}>
                            <center>HOME</center>
                        </TableCell>}
                        {getPlayerKeys("home_player").map((playerKey) => (
                            renderPlayerRow(playerKey)
                        ))} 
                        {(injury_report?.away_player1 || injury_report?.away_player2 || injury_report?.away_player3 || injury_report?.away_player4) && <TableCell sx={{ textAlign: "center", color: "#ffce19", fontSize: "10px", width: 80, borderRight: "1px solid #ccc", borderLeft: "1px solid #ccc" }} rowSpan={5}>
                            <center>AWAY</center>
                        </TableCell>}
                        {getPlayerKeys("away_player").map((playerKey) => (
                            renderPlayerRow(playerKey)
                        ))}
                    </TableRow>
                    <TableRow>
                    {(injury_report?.home_player1 || injury_report?.home_player2 || injury_report?.home_player3 || injury_report?.home_player4) && (<> <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }} />
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 80 }} />
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 60 }} />
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }} />
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>IMPACT LEVEL</center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>{injury_report.total_home_impact || "-"}</center>
                        </TableCell></>)}
                        {(injury_report?.away_player1 || injury_report?.away_player2 || injury_report?.away_player3 || injury_report?.away_player4) && (<> <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }} />
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }} />
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center />
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 60 }}>
                            <center>IMPACT LEVEL</center>
                        </TableCell>
                        <TableCell sx={{ textAlign: "center", color: "#cccccc", fontSize: "10px", width: 40 }}>
                            <center>{injury_report.total_away_impact || "-"}</center>
                        </TableCell> </>)}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default InjuryReport;

